<template>
  <div class="top-padding">
    <FaqComponent />
  </div>
</template>
<script>
import FaqComponent from "@/esf_utrecht_bankxl/core/components/faq/FaqComponent.vue";
import config from "@config";
export default {
  name: "FaqPage",
  components: {
    FaqComponent,
  },
  metaInfo() {
    return {
      title: this.$t("faq"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
