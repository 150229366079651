var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.relatedProduct != null)?_c('b-card-body',{directives:[{name:"match-heights",rawName:"v-match-heights",value:({ el: ['.price'] }),expression:"{ el: ['.price'] }"}],staticClass:"shopping-product-slider"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"product-img"},[_c('router-link',{attrs:{"to":("/" + (_vm.relatedProduct.url_key) + "/")},nativeOn:{"click":function($event){return _vm.$root.$emit('closeSearchModal')}}},[_c('b-img',{attrs:{"alt":_vm.relatedProduct.name.slice(0, 39),"src":_vm.relatedProduct.thumbnail.url
              ? _vm.relatedProduct.thumbnail.url
              : _vm.relatedProduct.thumbnail.medium}})],1)],1),_c('div',{staticClass:"txt-div"},[_c('p',{staticClass:"offer-tag",class:{
          'opacity-0':
            _vm.relatedProduct.price_range.maximum_price.discount.percent_off ==
            0,
        }},[_c('b-link',{staticClass:"offer-link"},[_vm._v(" - "+_vm._s(_vm.relatedProduct.price_range.maximum_price.discount.percent_off.toFixed( 0 ))+"% ")])],1),_c('div',{staticClass:"shopping-cart-icon"},[_c('span',{staticClass:"right"},[_c('b-link',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addToCart()}}},[_c('font-awesome-icon',{staticStyle:{"color":"#fff","font-size":"18px"},attrs:{"icon":"shopping-cart"}})],1)],1)]),_c('p',{staticClass:"price"},[(
            _vm.relatedProduct.price_range.minimum_price.regular_price.value !=
            _vm.relatedProduct.price_range.minimum_price.final_price.value
          )?_c('span',{staticClass:"old"},[_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.relatedProduct.price_range.minimum_price.regular_price.value )))])]):_vm._e(),_c('span',{staticClass:"new"},[_c('strong',[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.relatedProduct.price_range.minimum_price.final_price.value ))+" ")]),_vm._v(" "+_vm._s(_vm.relatedProduct.unit))])]),_c('div',{staticClass:"extra"},[_c('div',{staticClass:"left"},[_c('b-card-text',{staticClass:"productInfo"},[_c('router-link',{attrs:{"to":("/" + (_vm.relatedProduct.url_key) + "/")},nativeOn:{"click":function($event){return _vm.$root.$emit('closeSearchModal')}}},[_vm._v(" "+_vm._s(_vm.relatedProduct.name)+" ")])],1)],1),_c('div',{staticClass:"shopping-cart-wrap"},[(_vm.relatedProduct.DeliveryTime)?_c('b-link',{staticClass:"delivery-msg",style:(("color: " + (_vm.relatedProduct.DeliveryTime.color)))},[_vm._v(" "+_vm._s(_vm.relatedProduct.DeliveryTime.short)+" ")]):_vm._e()],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }