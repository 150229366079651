<template>
  <div class="tab-six tab-content-detail">
    <!-- <ProductDeleteComponent /> -->
    <b-modal
      id="wishlist-product-delete"
      class="product-modal-inner wishlist-product-model"
      content-class="defaul-modal"
      hide-footer
      centered
      size="lg"
    >
      <template #modal-title class="bold">
        {{ $t("remove_product_wishlist") }}
      </template>
      <div class="modal-text d-block border-bottom">
        <p>
          {{ $t("sure_remove_product_wishlist") }}
        </p>
      </div>
      <div class="modal-btn-box pt-20">
        <b-button variant="success" block @click="clickOk()">Ok</b-button>
        <b-button variant="cancel" block @click="clickCancel()"
          >{{ $t("back") }}
        </b-button>
      </div>
    </b-modal>
    <div class="heading bold pb-15">{{ $t("my_wishlist") }}</div>
    <div class="my-account-wishlist-main" v-if="wishlist.length > 0">
      <div
        class="pb-20 my-account-wishlist"
        v-for="wishItem in showProducts"
        :key="wishItem.id"
      >
        <div class="sub-heading pb-10">
          {{ $t("added_on") }} {{ convertDate(wishItem.added_at) }}
        </div>
        <WishlistCard
          :wishItem="wishItem"
          @removeProduct="removeFromWishList"
        />
        <!-- end -->
      </div>
      <div
        class="heading bold pb-15 xs-text-center return-item-btn mt-4 login-btn"
      >
        <b-button variant="success" @click="loadMore" v-if="showLoadMore">{{
          $t("show_more")
        }}</b-button>
        <b-button variant="cancel" @click="goBack()">{{ $t("back") }}</b-button>
      </div>
    </div>
    <div v-else>{{ $t("no_wishlist") }}</div>
  </div>
</template>
<script>
import WishlistCard from "@/esf_utrecht_bankxl/core/components/wishlist/WishlistCard";
export default {
  name: "AccountTabWishlist",
  components: { WishlistCard },
  data() {
    return {
      step: 4,
      prodsCount: 4,
      popupProduct: null,
    };
  },
  computed: {
    wishlist() {
      return this.$store.getters["wishlist/getWishItems"];
    },
    showProducts() {
      return this.wishlist.slice(0, this.prodsCount);
    },
    showLoadMore() {
      return this.wishlist.length > this.prodsCount;
    },
  },
  methods: {
    goBack() {
      this.$root.$emit("toggleAccountSidebar");
    },
    loadMore() {
      this.prodsCount += this.step;
    },
    removeFromWishList(product) {
      this.popupProduct = product;
      this.$bvModal.show("wishlist-product-delete");
    },
    async clickOk() {
      await this.$store.dispatch("wishlist/removeProductFromWishlist", {
        sku: this.popupProduct.sku,
      });
      this.popupProduct = null;
      this.$bvModal.hide("wishlist-product-delete");
    },
    clickCancel() {
      this.popupProduct = null;
      this.$bvModal.hide("wishlist-product-delete");
    },
    convertDate(date) {
      let month = [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ];

      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },
  },
};
</script>
