<template>
  <div class="shopping-cart top-padding">
    <ShoppingCartComponent />
  </div>
</template>
<script>
import ShoppingCartComponent from "@/esf_utrecht_bankxl/core/components/shopping-cart/ShoppingCartComponent.vue";
import config from "@config";
export default {
  name: "ShoppingCartPage",
  components: {
    ShoppingCartComponent,
  },
  metaInfo() {
    return {
      title: this.$t("shopping_cart"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
