<template>
  <b-container>
    <div class="contact-page">
      <ContactModalComponent />
      <div class="contact-cms">
        <cmsBlockSimple
          v-if="typeof contactTopContent == 'object'"
          :identifier="contactTopContent.identifier"
        />
      </div>
      <b-row>
        <b-col cols="12" lg="6" md="12">
          <div class="contact-form-wrap">
            <h2 class="contact-title">{{ $t("send_message") }}</h2>
            <div class="contact-form">
              <ContactForm type="contact"></ContactForm>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6" md="12">
          <cmsBlockSimple
            v-if="typeof contactBottomContent == 'object'"
            :identifier="contactBottomContent.identifier"
            class="h-100"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import ContactForm from "@/esf_utrecht_bankxl/core/components/contact/ContactForm";
import ContactModalComponent from "@/esf_utrecht_bankxl/core/components/contact/ContactModalComponent";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
export default {
  name: "ContactComponent",
  props: {
    msg: { type: String, required: false },
  },
  components: { ContactModalComponent, ContactForm, cmsBlockSimple },
  data() {
    return {
      form: {
        question: "",
        questionState: null,
        email: "",
        emailState: null,
        name: "",
        nameState: null,
        tel: "",
        telState: null,
        food: null,
        checked: [],
        showExtraInfo: false,
      },
      show: true,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      numberReg: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
    };
  },
  computed: {
    contactTopContent() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contact_top_content"
      );
    },
    contactBottomContent() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contact_bottom_content"
      );
    },
  },
  methods: {
    onSubmit() {
      let isOk = true;

      if (this.form.question == "") {
        isOk = false;
        this.form.questionState = false;
      } else {
        this.form.questionState = true;
      }
      if (this.form.name == "") {
        isOk = false;
        this.form.nameState = false;
      } else {
        this.form.nameState = true;
      }
      if (this.form.email == "") {
        isOk = false;
        this.form.emailState = false;
      } else if (!this.emailReg.test(this.form.email)) {
        isOk = false;
        this.form.emailState = false;
      } else {
        this.form.emailState = true;
      }
      if (this.form.showExtraInfo) {
        if (this.form.tel == "" || !this.numberReg.test(this.form.tel)) {
          isOk = false;
          this.form.telState = false;
        } else {
          this.form.telState = true;
        }
      }

      // const formData = {
      //   question: this.form.question,
      //   name: this.form.name,
      //   email: this.form.email,
      //   tel: this.form.tel,
      // };

      if (isOk) {
        this.$bvModal.show("contact-modal");
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
