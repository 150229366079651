<template>
  <div class="rma-start-request-user">
    <div class="returnable-orders" v-if="orders">
      <div class="returnable-order" v-for="order in orders" :key="order.id">
        <div class="order-tab-block-heading pb-10">
          <span>
            {{ convertDate(order.order_date) }} | {{ $t("order_no") }}
            {{ order.number }}
          </span>
          <span>
            <b-button
              class="btn btn-success"
              v-if="order.return_available"
              @click="requestReturn(order)"
              >{{ $t("rma_account_order_request_return") }}</b-button
            >
          </span>
        </div>
        <div
          class="order-tab-block mb-3"
          v-for="item in order.items"
          :key="item.id"
        >
          <div class="left-h">
            <b-img :src="item.image.url" alt="" v-if="item.image" />
          </div>
          <div class="left-r">
            <div class="product-acc-content">
              <h4
                class="product_hd bold-l"
                :class="order.status === 'Pending' ? 'red' : 'success'"
              >
                {{ order.status }}
              </h4>
              <p>{{ item.product_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" v-else>
      {{ $t("rma_no_returnable_orders") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
    };
  },

  created() {},

  mounted() {
    this.getReturnableOrders();
  },

  methods: {
    async getReturnableOrders() {
      let allOrders = await this.$store.getters["user/getMyOrders"];

      console.log(allOrders);

      let returnableOrders = allOrders.filter((order) => {
        return order.return_available;
      });

      this.orders = [...returnableOrders];
    },

    convertDate(date) {
      let month = [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ];
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },

    requestReturn(order) {
      this.$router.push({
        path: "/account/",
        query: { name: "returns", new: order.number },
      });
    },
  },
};
</script>
