<template>
  <div class="switcher">
    <div v-if="kleur && kleur.length > 0">
      Kleuren:
      <ul class="color_swatch">
        <li
          v-for="(value, index) in kleur"
          :key="index"
          :style="{
            backgroundColor: value.swatch_data.value,
            color: value.swatch_data.value,
          }"
          class="color_switcher"
          :class="{ disabled_swatch: !value.is_available }"
        >
          <b-link
            :to="'/' + value.product.url_key + '/'"
            v-if="value.is_available"
          >
            <span
              v-if="value.is_current && value.swatch_data.value != '#ffffff'"
              :title="value.label"
              style="border-color: #fff"
            >
              <font-awesome-icon icon="check" style="color: #fff" />
            </span>
            <span
              v-else-if="
                value.is_current && value.swatch_data.value == '#ffffff'
              "
              :title="value.label"
              style="border-color: #000"
            >
              <font-awesome-icon icon="check" style="color: #000" />
            </span>
            <span
              v-else-if="
                !value.is_current && value.swatch_data.value != '#ffffff'
              "
              :title="value.label"
              style="border-color: #fff"
            >
              &nbsp;</span
            >
            <span v-else style="border-color: #000" :title="value.label">
              &nbsp;</span
            ></b-link
          >
          <span v-else>
            <span
              v-if="value.is_current && value.swatch_data.value != '#ffffff'"
              :title="value.label"
              style="border-color: #fff"
            >
              <font-awesome-icon icon="check" style="color: #fff" />
            </span>
            <span
              v-else-if="
                value.is_current && value.swatch_data.value == '#ffffff'
              "
              :title="value.label"
              style="border-color: #000"
            >
              <font-awesome-icon icon="check" style="color: #000" />
            </span>
            <span
              v-else-if="
                !value.is_current && value.swatch_data.value != '#ffffff'
              "
              :title="value.label"
              style="border-color: #fff"
            >
              &nbsp;</span
            >
            <span v-else style="border-color: #000" :title="value.label">
              &nbsp;</span
            ></span
          >
        </li>
      </ul>
    </div>
    <div v-if="other && other.label">
      {{ other.label }}:
      <ul class="other_swatch">
        <li
          v-for="(value, index) in other.values"
          :key="index"
          :style="{
            backgroundColor: value.is_current ? '#000' : '#dddcdc',
          }"
          :class="{ disabled_swatch: !value.is_available }"
        >
          <b-link
            :to="'/' + value.product.url_key + '/'"
            :style="{ color: value.is_current ? '#fff' : '#000' }"
            v-if="value.is_available"
          >
            <font-awesome-icon
              icon="check"
              style="color: #fff"
              v-if="value.is_current"
            />
            {{ value.label }}</b-link
          >
          <span
            :style="{
              color: value.is_current ? '#fff' : '#000',
            }"
            v-else
          >
            <font-awesome-icon
              icon="check"
              style="color: #fff"
              v-if="value.is_current"
            />
            {{ value.label }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "Switcher",
  props: {
    SwitcherConfig: {
      required: true,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    kleur() {
      var returnValue = [];
      this.SwitcherConfig.forEach(function (attribute) {
        if (attribute.attribute_code == "kleur") {
          returnValue = attribute.values;
        }
      });
      return returnValue;
    },
    other() {
      var returnValue = {};
      this.SwitcherConfig.forEach(function (attribute) {
        if (attribute.attribute_code != "kleur") {
          returnValue = { label: attribute.label, values: attribute.values };
        }
      });
      return returnValue;
    },
  },
};
</script>
