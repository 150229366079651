<template>
  <b-container>
    <div class="product-details-main" v-if="product">
      <div class="main-product-page">
        <div class="headin-wrap">
          <h3 class="product-heading">{{ productName }}</h3>
        </div>
        <div class="top-inner">
          <div class="single-product">
            <div class="product-slider">
              <ProductGallery
                :images="mediaGallery"
                :product-discount="productDiscount"
                :video="video"
                :product="product"
              />
            </div>
            <ProductParameters
              :selected-config-product="
                selectedConfigProduct && selectedConfigProduct
              "
              :productName="productName"
            />
          </div>
        </div>

        <ProductParametersGrouped
          v-if="product.__typename == 'GroupedProduct'"
        />

        <ProductBottom
          :selected-config-product="
            selectedConfigProduct && selectedConfigProduct
          "
        />
      </div>
    </div>
    <div v-else>
      {{ $t("Product not found or out of stock") }}
    </div>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";

import ProductGallery from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductGallery";
import ProductParameters from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductParameters";
import ProductParametersGrouped from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductParametersGrouped";
import ProductBottom from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductBottom";

export default {
  name: "ProductComponent",
  data() {
    return {
      selectedConfigProduct: null,
    };
  },
  components: {
    ProductGallery,
    ProductParameters,
    ProductParametersGrouped,
    ProductBottom,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentBundle",
    }),
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.product.sku
      );
    },
    mediaGallery() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.media_gallery
        : this.product.media_gallery;
    },
    productDiscount() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.price_range.maximum_price.discount
            .percent_off
        : this.product.price_range.maximum_price.discount.percent_off;
    },
    productName() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.name
        : this.product.name;
    },
    video() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.youtube_url
        : this.product.youtube_url;
    },
  },

  methods: {
    updateWish() {
      if (this.isInWishlist) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.product.sku,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.currentChildSku;
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$store.dispatch("wishlist/addProductToWishlist", {
              sku: childSku,
              parentSku: this.product.sku,
            });
          }
        }
      }
    },
    updateConfigProduct(sku) {
      if (sku != null) {
        if (this.productConfig && this.productConfig.variants) {
          const variant = this.productConfig.variants.find(
            (item) => item.product.sku === sku
          );
          if (variant && variant.product) {
            this.selectedConfigProduct = { ...variant.product };
            this.$store.dispatch("product/loadRelated", {
              sku: variant.product.sku,
            });
            this.$store.dispatch("productSerie/loadSerie", {
              serie_id: variant.product.serie,
            });
          }
        }
      } else if (this.product) {
        this.selectedConfigProduct = null;
        this.$store.dispatch("product/loadRelated", {
          sku: this.product.sku,
        });
        this.$store.dispatch("productSerie/loadSerie", {
          serie_id: this.product.serie,
        });
      } else {
        this.selectedConfigProduct = null;
      }
    },
  },
  mounted() {
    if (this.product) {
      this.$store.dispatch("productSerie/loadSerie", {
        serie_id: this.product.serie,
      });
    }
    if (!isServer) {
      if (this.$gtm.enabled()) {
        Logger.debug("Watch Product", "product.vue", "CALLED")();
        window.dataLayer.push({
          event: "eec.detail",
          ecommerce: {
            detail: {
              products: [
                {
                  name: this.product.name,
                  id: this.product.sku,
                },
              ],
            },
          },
        });
      }
    }
  },
  watch: {
    product() {
      if (!isServer) {
        if (this.$gtm.enabled()) {
          Logger.debug("Watch Product", "product.vue", this.product)();
          window.dataLayer.push({
            event: "eec.detail",
            ecommerce: {
              detail: {
                products: [
                  {
                    name: this.product.name,
                    id: this.product.sku,
                  },
                ],
              },
            },
          });
        }
      }
      this.$store.dispatch("productSerie/loadSerie", {
        serie_id: this.product.serie,
      });
    },
    currentChildSku(sku) {
      this.updateConfigProduct(sku);
    },
  },
};
</script>
