<template>
  <div id="" class="search-results-suggestion">
    <div class="custom-title">
      {{ $t("search_result_for") }} {{ searchWord }}:
    </div>
    <div class="suggestion-list-wrap" v-if="searchSuggestions.length > 0">
      <div class="sub-title">{{ $t("you_mean") }}:</div>
      <ul class="suggestion-list">
        <li
          v-for="(suggestion, idx) in searchSuggestions"
          :key="idx"
          @click="$router.push('/search/?q=' + suggestion.word)"
        >
          <b-link>{{ suggestion.word }}</b-link>
        </li>
      </ul>
    </div>
    <div class="suggestion-list-wrap" v-if="searchRecommendations.length > 0">
      <div class="sub-title">{{ $t("related_search_term") }}:</div>
      <ul class="suggestion-list">
        <li
          v-for="(recommendation, idx) in searchRecommendations"
          :key="idx"
          @click="$router.push('/search/?q=' + recommendation.word)"
        >
          <b-link>{{ recommendation.word }}</b-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDescription",
  props: {
    searchSuggestions: {
      type: Array,
    },
    searchRecommendations: {
      type: Array,
    },
  },
  computed: {
    searchWord() {
      return this.$route.query.q;
    },
  },
};
</script>

<style>
</style>