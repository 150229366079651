<template>
  <section class="blog-page">
    <b-container>
      <Breadcrumbs />
      <section class="static pt-7 blog-detail" v-if="cmsPageContent != null">
        <div fluid class="p-0">
          <b-row class="mob-show m-0">
            <b-col cols="12" xl="3" lg="3" md="3" class="p-0">
              <img
                class="blog-banner single-blog"
                :src="cmsPageContent.featured_image"
                :alt="cmsPageContent.featured_img_alt"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col>
              <div class="blog-banner">
                <img
                  :src="cmsPageContent.featured_image"
                  :alt="cmsPageContent.featured_img_alt"
                />
              </div>
            </b-col>
          </b-row>
          <div class="single-blog">
            <b-row>
              <b-col cols="12" xl="3" lg="3" md="4">
                <div class="mobile-back">
                  <b-link @click.native="$router.back()"
                    ><b-icon icon="chevron-left"></b-icon
                    >{{ $t("go_back") }}</b-link
                  >
                </div>
                <h4 class="blog-title">Blog</h4>
                <div class="blog-search" ref="blogSearch">
                  <b-form-input
                    type="text"
                    :placeholder="$t('search_blog')"
                    class="search-input"
                    :class="{ focused: isFilterActive }"
                    @keyup="searchBlog"
                    @focus="onFocus"
                  ></b-form-input>
                  <div class="search-btn">
                    <i class="fa fa-search"></i>
                  </div>
                  <div
                    class="blog-searchResults"
                    :class="{ show: isFilterActive }"
                  >
                    <div v-if="filteredBlogs.length">
                      <div
                        class="result-wrap"
                        v-for="(item, index) in filteredBlogs.slice(0, 3)"
                        :key="index"
                      >
                        <b-link @click="goToBlog(item.identifier)">
                          <div class="feature-img">
                            <img
                              :src="item.featured_image"
                              :alt="item.featured_img_alt"
                            />
                          </div>
                          <div class="blog-detail">
                            <div class="blog-title">{{ item.title }}</div>
                          </div>
                        </b-link>
                      </div>
                    </div>
                    <div class="no-data-found" v-else>
                      <p>{{ $t("no_data_found") }}</p>
                    </div>
                  </div>
                </div>
                <ul class="filter-list">
                  <li @click="$router.push({ name: 'blogs' })">Alles</li>
                  <li
                    v-for="category of blogCategories"
                    :key="category.category_id"
                    @click="goToCategory(category)"
                  >
                    {{ category.title }}
                  </li>
                </ul>
              </b-col>
              <b-col cols="12" xl="6" lg="6" md="8">
                <div class="blog-content">
                  <h4>{{ cmsPageContent.title }}</h4>
                  <div class="meta-description">
                    <div class="blog-date">
                      <i class="fas fa-calendar-alt"></i>
                      <span>{{ blogtDate(cmsPageContent.creation_time) }}</span>
                    </div>
                  </div>

                  <div class="short-content">
                    <p v-html="cmsPageContent.filtered_content"></p>
                  </div>

                  <div class="blog-share-option">
                    <b-icon icon="twitter"></b-icon>
                    <ul>
                      <li><span>deel dit bericht</span></li>
                      <li>
                        <ShareNetwork
                          network="facebook"
                          :url="shareUrl"
                          :title="cmsPageContent && cmsPageContent.meta_title"
                          :description="
                            cmsPageContent && cmsPageContent.meta_description
                          "
                        >
                          <i class="fab fa-facebook-f"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="twitter"
                          :url="shareUrl"
                          :title="cmsPageContent && cmsPageContent.meta_title"
                        >
                          <i class="fab fa-twitter"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                          network="email"
                          :url="shareUrl"
                          :title="cmsPageContent && cmsPageContent.meta_title"
                          :description="
                            cmsPageContent && cmsPageContent.meta_description
                          "
                        >
                          <i class="fa fa-envelope"></i>
                        </ShareNetwork>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" xl="3" lg="3" md="12">
                <div class="author-detail">
                  <div>
                    <!-- <img
                      :src="
                        `${app_url}/media/wysiwyg/blog/author/${cmsPageContent.author.identifier}.jpg`
                      "
                      alt="blog-author"
                      @error="setDefaultImage"
                    /> -->
                    <b-img :src="homingLogo" />

                    <h4 class="author-name">
                      {{ cmsPageContent.author && cmsPageContent.author.name }}
                    </h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </section>
    </b-container>
  </section>
</template>

<script>
import homingLogo from "@/esf_utrecht_bankxl/assets/images/homig-xl.png";
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import config from "@config";
import xlLogo from "@/esf_utrecht_bankxl/assets/images/xl-logo.png";
export default {
  name: "BlogDetail",
  components: {
    Breadcrumbs,
  },
  computed: {
    cmsPageContent() {
      return this.$store.getters["blog/getBlog"];
    },
    identifier() {
      return this.$route.params.code;
    },
    dateFormat() {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return options;
    },
    shareUrl() {
      return (
        config.app_url +
        this.$route.path.slice(1) +
        (this.$route.path.slice(-1) == "/" ? "" : "/")
      );
    },
    blogCategories() {
      return this.$store.getters["blog_additional/getBlogCategories"];
    },
    allFilteredBlogs() {
      return this.$store.getters["blog_additional/getFilteredBlogs"];
    },
    getSingleBlogContent() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "single_blog_content"
      );
    },
  },
  watch: {
    identifier() {
      this.$store.dispatch("blog/loadBlog", {
        identifier: this.$route.params.code,
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("blog/loadBlog", {
      identifier: this.$route.params.code,
    });
    const bcrumb = {
      current: this.cmsPageContent.title,
      routes: [
        {
          name: "Blog",
          route_link: "/blog/",
        },
      ],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
    await this.$store.dispatch("blog_additional/loadBlogCategories");
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_title ?? this.cmsPageContent.title
            : "Blog",
        },
        {
          name: "keywords",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_keywords ?? ""
            : "",
        },
        {
          name: "description",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_description
            : "Blog",
        },
      ],
      title: this.cmsPageContent
        ? this.cmsPageContent.meta_title ?? this.cmsPageContent.title
        : "Blog",
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Blog",
    },
    visible: false,
    isFilterActive: false,
    filteredBlogs: [],
    app_url: config.app_url,
    xlLogo,
    homingLogo,
  }),
  methods: {
    handleHide() {
      this.visible = false;
    },
    showLightbox() {
      this.visible = true;
    },
    blogtDate(date) {
      let formattedDate = new Date(date).toLocaleDateString(
        "nl-NL",
        this.dateFormat
      );
      return formattedDate;
    },
    async searchBlog(event) {
      const searchVal = event.target.value;
      if (searchVal.length > 0) {
        this.isFilterActive = true;
        await this.$store.dispatch("blog_additional/loadBlogsByFilter", {
          searchVal,
        });
        this.filteredBlogs = this.allFilteredBlogs.slice(0, this.blogsPerPage);
      } else {
        this.isFilterActive = false;
      }
    },
    goToCategory(category) {
      this.$router.push({
        name: "blog-category",
        params: { category_id: category.identifier },
      });
    },
    goToBlog(identifier) {
      this.$router.push("/blog/" + identifier + "/");
      this.isFilterActive = false;
    },
    onFocus(event) {
      if (event.target.value != "") {
        this.isFilterActive = true;
      }
    },
    close(e) {
      const element = this.$refs.blogSearch;
      if (!element.parentElement.contains(e.target)) {
        this.isFilterActive = false;
      }
    },
    setDefaultImage(event) {
      event.target.src = this.xlLogo;
    },
  },
};
</script>
<style lang="scss"></style>
