<template>
  <b-container>
    <div class="wishlist-page-inner">
      <div class="page-heading">
        <h3>
          {{ $t("wishlist") }}
          <span>
            <b-link class="span-txt">({{ wishlist.length }})</b-link>
          </span>
        </h3>
        <div class="sub-heading">
          {{ $t("find_wish_list_here") }}
        </div>
      </div>
      <div class="desktop-show">
        <div class="wishlist-product-wrap">
          <WishlistProductCardComponent
            v-for="(item, index) in wishlist"
            :key="index"
            :item="item"
          />
        </div>
      </div>
      <div class="mob-tab-show">
        <b-row class="wishlist-product-wrap-mobile">
          <b-col cols="12" v-for="(wishItem, index) in wishlist" :key="index">
            <WishlistCard
              :wishItem="wishItem"
              @removeProduct="removeFromWishList"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>
<script>
import WishlistProductCardComponent from "@/esf_utrecht_bankxl/core/components/wishlist/WishlistProductCardComponent";
import WishlistCard from "@/esf_utrecht_bankxl/core/components/wishlist/WishlistCard";
export default {
  name: "WishlistComponent",
  components: {
    WishlistProductCardComponent,
    WishlistCard,
  },
  computed: {
    wishlist() {
      return this.$store.getters["wishlist/getWishItems"];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    async removeFromWishList(product) {
      await this.$store.dispatch("wishlist/removeProductFromWishlist", {
        sku: product.sku,
      });
    },
  },
};
</script>
