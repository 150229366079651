<template>
  <section class="page-content">
    <div>
      <SearchPageContentFilter
        :sorting="sorting"
        @change-sorting="changeSorting"
        :select-options="selectOptions"
        :prods-per-page="prodsPerPage"
        @change-per-page="changePerPage"
        :products-count="totalProducts"
      />
      <div>
        <div v-if="isLoading">
          <div class="pro-loader">
            <div class="reverse-spinner"></div>
          </div>
        </div>
        <div v-else-if="totalProducts === 0">
          <p class="text-center">{{ $t("no_products") }}</p>
        </div>
        <div v-else class="row mobile-search-grid">
          <b-col
            lg="3"
            md="4"
            sm="4"
            cols="4"
            v-for="product of productList"
            :key="product.id"
            class=""
          >
            <ProductCard :product="product" />
          </b-col>
        </div>
        <div
          v-if="totalProducts > 0"
          class="pb-30 pagination-header category-right-top-list-header mt-0"
        >
          <div class="category-list-left">
            {{ totalProducts }} {{ $t("result") }} | &nbsp; {{ $t("sort_by") }}
            <span class="count-icon">
              <select
                id="collection"
                class="filter-list bold"
                :value="sorting"
                @change="changeSorting"
              >
                <option
                  v-for="option in selectOptions"
                  v-bind:key="option.value"
                  v-bind:value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </span>
          </div>
          <div class="category-list-right pagination-wrap">
            <b-pagination
              :value="currentPage"
              @change="changePage"
              :per-page="prodsPerPage"
              :total-rows="totalProducts"
              :limit="3"
              :prev-class="{ 'd-none': currentPage == 1 }"
              :next-class="{
                'd-none':
                  currentPage == Math.ceil(totalProducts / prodsPerPage),
              }"
            >
              <template #page="{ page }">
                <b-link :to="goToPage(page)">
                  {{ page }}
                </b-link>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchPageContentFilter from "@/esf_utrecht_bankxl/core/components/search/SearchPageContentFilter";
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard";
import config from "@config";

export default {
  name: "SearchPageContent",
  components: {
    SearchPageContentFilter,
    ProductCard,
  },
  data: () => ({}),
  props: {
    productsCount: Number,
  },
  mounted() {
    const { page } = this.$route.query;
    const { path } = this.$route;
    const query = { ...this.$route.query };

    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }

    const pageSize = config.products?.perPage;
    const perPage = this.$store.getters["search/getProductsPerPage"];
    if (perPage != pageSize) query["page-size"] = perPage;

    this.$router.replace({ path, query });
  },
  computed: {
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting() {
      return this.$store.getters["search/getSorting"];
    },
    isLoading() {
      return this.$store.getters["search/getLoadProducts"];
    },
    showProducts() {
      return this.$store.getters["search/getShowProducts"];
    },

    productList() {
      if (this.showProducts.length == 0) {
        return [];
      } else {
        return this.showProducts.filter(item => item !== null);
      }
    },
    currentPage() {
      return +this.$store.getters["search/getCurrentPage"];
    },
    prodsPerPage() {
      return +this.$store.getters["search/getProductsPerPage"];
    },
    totalProducts() {
      return +this.$store.getters["search/getTotalProducts"];
    },
  },
  methods: {
    changeSorting(event) {
      const sortVal = event.target.value;
      this.$store.commit("search/setSorting", sortVal);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      const { sortDefault } = config;
      if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    changePage(pageNo) {
      this.$store.commit("search/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });
      this.$store.commit("search/setProductsPerPage", perPage);
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  font-size: 16px;
}
.pro-loader {
  text-align: center;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0px;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>
