var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-dynamic_form","okVariant":"success","okTitle":"Ok","ok-only":""},on:{"ok":_vm.clickDynFormOk}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.msg)}})]),_c('h2',{staticClass:"semiBold-18"},[_vm._v(_vm._s(_vm.form.title))]),_c('div',{staticClass:"product-form-wrap"},[_c('div',{staticClass:"product-form-inner"},_vm._l((_vm.form.fieldsets),function(set,idx){return _c('div',{key:"set" + idx,staticClass:"product-form-box"},_vm._l((set.fields),function(field,index){return _c('div',{key:"fields" + index},[(field.type == 'checkbox')?_c('div',[_c('b-form-checkbox',{ref:("prizeFilter-" + index),refInFor:true,attrs:{"id":"set" + idx + "field" + index + "group","name":field.name,"value":field.checkedValue,"unchecked-value":field.uncheckedValue},on:{"change":_vm.onChange},model:{value:(_vm.formValue[idx][index]),callback:function ($$v) {_vm.$set(_vm.formValue[idx], index, $$v)},expression:"formValue[idx][index]"}},[_vm._v(" "+_vm._s(field.label)+" ")])],1):_vm._e(),(field.name == 'telefoonnummer' && !_vm.notChecked)?_c('div',[_c('b-form-group',{staticClass:"account-inputs",attrs:{"id":"set" + idx + "field" + index + "group","label":field.label,"label-class":_vm.is_required(field),"label-for":"set" + idx + "field" + index}},[_c('b-form-input',{attrs:{"id":"set" + idx + "field" + index,"type":field.inputType,"aria-describedby":"set" + idx + "field" + index + "feedback","state":_vm.formState[idx][index],"aria-required":("" + (field.validation.some(
                  function (item) { return item.type == 'required'; }
                )))},model:{value:(_vm.formValue[idx][index]),callback:function ($$v) {_vm.$set(_vm.formValue[idx], index, $$v)},expression:"formValue[idx][index]"}}),_c('b-form-invalid-feedback',{attrs:{"id":"set" + idx + "field" + index + "feedback"}},[_vm._v(_vm._s(_vm.formError[idx][index])+" ")])],1)],1):_vm._e(),(field.type == 'input' && field.name != 'telefoonnummer')?_c('div',[_c('b-form-group',{staticClass:"account-inputs",attrs:{"id":"set" + idx + "field" + index + "group","label":field.label,"label-class":_vm.is_required(field),"label-for":"set" + idx + "field" + index}},[_c('b-form-input',{attrs:{"id":"set" + idx + "field" + index,"type":field.inputType,"aria-describedby":"set" + idx + "field" + index + "feedback","state":_vm.formState[idx][index],"aria-required":("" + (field.validation.some(
                  function (item) { return item.type == 'required'; }
                )))},model:{value:(_vm.formValue[idx][index]),callback:function ($$v) {_vm.$set(_vm.formValue[idx], index, $$v)},expression:"formValue[idx][index]"}}),_c('b-form-invalid-feedback',{attrs:{"id":"set" + idx + "field" + index + "feedback"}},[_vm._v(_vm._s(_vm.formError[idx][index])+" ")])],1)],1):_vm._e(),(field.type == 'select')?_c('div',[_c('b-form-group',{staticClass:"account-inputs",attrs:{"id":"set" + idx + "field" + index + "group","label":field.label,"label-class":_vm.is_required(field),"label-for":"set" + idx + "field" + index}},[_c('b-form-select',{staticClass:"select",attrs:{"id":"set" + idx + "field" + index,"aria-describedby":"set" + idx + "field" + index + "feedback","state":_vm.formState[idx][index],"aria-required":("" + (field.validation.some(
                  function (item) { return item.type == 'required'; }
                )))},model:{value:(_vm.formValue[idx][index]),callback:function ($$v) {_vm.$set(_vm.formValue[idx], index, $$v)},expression:"formValue[idx][index]"}},_vm._l((field.options),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt))])}),1),_c('b-form-invalid-feedback',{attrs:{"id":"set" + idx + "field" + index + "feedback"}},[_vm._v(_vm._s(_vm.formError[idx][index])+" ")])],1)],1):_vm._e(),(field.type == 'textarea')?_c('div',[_c('b-form-group',{staticClass:"account-inputs",attrs:{"id":"set" + idx + "field" + index + "group","label":field.label,"label-class":_vm.is_required(field),"label-for":"set" + idx + "field" + index}},[_c('b-form-textarea',{attrs:{"id":"set" + idx + "field" + index,"aria-describedby":"set" + idx + "field" + index + "feedback","state":_vm.formState[idx][index],"aria-required":("" + (field.validation.some(
                  function (item) { return item.type == 'required'; }
                ))),"rows":"3"},model:{value:(_vm.formValue[idx][index]),callback:function ($$v) {_vm.$set(_vm.formValue[idx], index, $$v)},expression:"formValue[idx][index]"}},[_vm._v(_vm._s(_vm.formValue[idx][index]))]),_c('b-form-invalid-feedback',{attrs:{"id":"set" + idx + "field" + index + "feedback"}},[_vm._v(_vm._s(_vm.formError[idx][index])+" ")])],1)],1):_vm._e()])}),0)}),0),_c('div',{staticClass:"required-field"},[_vm._v("*"+_vm._s(_vm.$t("required_field")))]),_c('b-button',{staticClass:"btn-success",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.sendForm()}}},[_vm._v(_vm._s(_vm.$t("send")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }