<template>
  <b-container>
    <div class="shopping-cart-wrapper">
      <h3 class="page-heading">
        {{ $t("this_in_your_shopping_cart") }}
        <span class="product-count"
          >({{ cartCount }} {{ $t("articles") }})
        </span>
      </h3>
      <ShoppingCartProductComponent />
    </div>
  </b-container>
</template>
<script>
import ShoppingCartProductComponent from "@/esf_utrecht_bankxl/core/components/shopping-cart/ShoppingCartProductComponent";
import shoppingImg from "@/esf_utrecht_bankxl/assets/images/s-prd-img.jpg";
import Cart from "@storefront/core/modules/cart/mixins";
export default {
  name: "ShoppingCartComponent",
  components: { ShoppingCartProductComponent },
  mixins: [Cart],
  data() {
    return {
      shoppingImg,
    };
  },
};
</script>