<template>
  <div class="wishlist-page">
    <WishlistComponent />
  </div>
</template>
<script>
import WishlistComponent from "@/esf_utrecht_bankxl/core/components/wishlist/WishlistComponent";
import config from "@config";
export default {
  name: "WishlistPageView",
  components: {
    WishlistComponent,
  },
  metaInfo() {
    return {
      title: this.$t("wishlist"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
