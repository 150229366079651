var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"switcher"},[(_vm.kleur && _vm.kleur.length > 0)?_c('div',[_vm._v(" Kleuren: "),_c('ul',{staticClass:"color_swatch"},_vm._l((_vm.kleur),function(value,index){return _c('li',{key:index,staticClass:"color_switcher",class:{ disabled_swatch: !value.is_available },style:({
          backgroundColor: value.swatch_data.value,
          color: value.swatch_data.value,
        })},[(value.is_available)?_c('b-link',{attrs:{"to":'/' + value.product.url_key + '/'}},[(value.is_current && value.swatch_data.value != '#ffffff')?_c('span',{staticStyle:{"border-color":"#fff"},attrs:{"title":value.label}},[_c('font-awesome-icon',{staticStyle:{"color":"#fff"},attrs:{"icon":"check"}})],1):(
              value.is_current && value.swatch_data.value == '#ffffff'
            )?_c('span',{staticStyle:{"border-color":"#000"},attrs:{"title":value.label}},[_c('font-awesome-icon',{staticStyle:{"color":"#000"},attrs:{"icon":"check"}})],1):(
              !value.is_current && value.swatch_data.value != '#ffffff'
            )?_c('span',{staticStyle:{"border-color":"#fff"},attrs:{"title":value.label}}):_c('span',{staticStyle:{"border-color":"#000"},attrs:{"title":value.label}})]):_c('span',[(value.is_current && value.swatch_data.value != '#ffffff')?_c('span',{staticStyle:{"border-color":"#fff"},attrs:{"title":value.label}},[_c('font-awesome-icon',{staticStyle:{"color":"#fff"},attrs:{"icon":"check"}})],1):(
              value.is_current && value.swatch_data.value == '#ffffff'
            )?_c('span',{staticStyle:{"border-color":"#000"},attrs:{"title":value.label}},[_c('font-awesome-icon',{staticStyle:{"color":"#000"},attrs:{"icon":"check"}})],1):(
              !value.is_current && value.swatch_data.value != '#ffffff'
            )?_c('span',{staticStyle:{"border-color":"#fff"},attrs:{"title":value.label}}):_c('span',{staticStyle:{"border-color":"#000"},attrs:{"title":value.label}})])],1)}),0)]):_vm._e(),(_vm.other && _vm.other.label)?_c('div',[_vm._v(" "+_vm._s(_vm.other.label)+": "),_c('ul',{staticClass:"other_swatch"},_vm._l((_vm.other.values),function(value,index){return _c('li',{key:index,class:{ disabled_swatch: !value.is_available },style:({
          backgroundColor: value.is_current ? '#000' : '#dddcdc',
        })},[(value.is_available)?_c('b-link',{style:({ color: value.is_current ? '#fff' : '#000' }),attrs:{"to":'/' + value.product.url_key + '/'}},[(value.is_current)?_c('font-awesome-icon',{staticStyle:{"color":"#fff"},attrs:{"icon":"check"}}):_vm._e(),_vm._v(" "+_vm._s(value.label))],1):_c('span',{style:({
            color: value.is_current ? '#fff' : '#000',
          })},[(value.is_current)?_c('font-awesome-icon',{staticStyle:{"color":"#fff"},attrs:{"icon":"check"}}):_vm._e(),_vm._v(" "+_vm._s(value.label))],1)],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }