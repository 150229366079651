<template>
  <div class="home-page">
    <ProductComponent class="top-padding" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductComponent from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductComponent.vue";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "Product",
  components: {
    ProductComponent,
  },
  computed: {
    ...mapGetters({ product: "product/getCurrentProduct" }),
  },
  metaInfo() {
    Logger.debug("metaInfo", "Product", config)();
    return {
      meta: [
        { name: "title", content: this.product && this.product.meta_title },
        {
          name: "keywords",
          content:
            this.product && this.product.meta_keyword
              ? this.product.meta_keyword
              : "",
        },
        {
          name: "description",
          content:
            this.product && this.product.meta_description
              ? this.product.meta_description
              : "",
        },
        { property: "og:type", content: "product" },
        {
          property: "og:title",
          content:
            this.product && this.product.meta_title
              ? this.product.meta_title
              : "",
        },
        {
          property: "og:image",
          content:
            this.product && this.product.image.url
              ? this.product.image.url
              : "",
        },
        {
          property: "og:description",
          content:
            this.product && this.product.meta_description
              ? this.product.meta_description
              : "",
        },
        {
          property: "product:price:amount",
          content:
            this.product &&
            this.product.price_range.minimum_price.final_price.value
              ? this.product.price_range.minimum_price.final_price.value.toFixed(
                  2
                )
              : "",
        },
        {
          property: "product:price:currency",
          content: "EUR",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title:
        this.product && this.product.name
          ? this.product.name
          : this.product && this.product.meta_title
          ? this.product.meta_title
          : "",
      link: [
        {
          rel: "canonical",
          href:
            this.product && this.product.canonical_url
              ? config.app_url + this.product.canonical_url
              : config.app_url +
                (/\/$/.test(this.$route.fullPath)
                  ? this.$route.fullPath
                  : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
