<template>
  <div v-if="order" class="order-detail">
    <div class="heading bold pb-15">
      {{ $t("order_detail_title") }}
    </div>
    <div class="sub-heading">
      {{ $t("order_detail_subtitle") }}: {{ order.number }}
    </div>
    <div class="view-order-wrap">
      <div
        class="view-order-single"
        v-for="product in order.items"
        :key="product.product_sku"
      >
        <div class="view-order-img">
          <b-img :src="product.image.url" alt="" width="170px" />
        </div>
        <div class="view-order-info">
          <b-link :to="'/' + product.product_url_key + '/'">{{
            product.product_name
          }}</b-link>
          <div class="price">
            {{ formatCurrency(product.product_sale_price_incl_tax.value) }}
            <span v-if="product.unit">
              {{ product.unit }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="order-detail-bottom">
      <div class="bottom-left">
        <div class="top">
          <div class="date">
            {{ $t("order_date") }}
            <span> {{ convertDate(order.order_date) }}</span>
          </div>
          <div class="status">
            {{ $t("status") }}:
            <span>{{ order.status }}</span>
          </div>
          <div class="address-box">
            <div>
              {{ order.billing_address.firstname }}
              {{ order.billing_address.lastname }}
            </div>
            <div>{{ order.billing_address.street[0] }}</div>
            <div>
              {{ order.billing_address.postcode }}
              {{ order.billing_address.city }}
            </div>
            <div class="tel-no">
              {{ order.billing_address.telephone }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-right">
        <div class="total">
          <label>{{ $t("total_amount") }}:</label>
          <p>{{ formatCurrency(order.total.base_grand_total.value) }}</p>
        </div>
      </div>
    </div>
    <div class="order-detail-footer login-btn">
      <b-button variant="cancel" @click="$emit('back')">{{
        $t("back")
      }}</b-button>
      <b-button variant="cancel" v-if="order.return_available" @click="requestReturn">{{
        $t("rma_account_order_request_return")
      }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountTabOrdersDetail",
  props: ["order"],
  methods: {
    convertDate(date) {
      let month = [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ];
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
    
    requestReturn() {
        this.$router.push({path: '/account/', query: {name: 'returns', new: this.order.number}});
    }
  },
};
</script>

<style>
</style>
