<template>
  <div class="tab-three tab-returns tab-content-detail rma-page">
    <NewRequest
      v-if="newRequest.active"
      :incrementId="newRequest.increment_id"
      @submitted="addedReturnRequest"
    />
    <RequestDetails
      :returnOrder="requestDetails.request"
      v-else-if="requestDetails.active"
      @back="closeDetails"
    />
    <RequestOverview
      ref="returnOverview"
      v-show="!newRequest.active && !requestDetails.active"
    />
  </div>
</template>

<script>
import NewRequest from "./NewRequest.vue";
import RequestDetails from "./RequestDetails.vue";
import RequestOverview from "./RequestOverview.vue";

export default {
  components: {
    NewRequest,
    RequestDetails,
    RequestOverview,
  },

  data() {
    return {
      newRequest: {
        active: false,
        increment_id: null,
      },
      requestDetails: {
        active: false,
        request: null,
      },
    };
  },

  watch: {
    tabName() {
      this.$router.push({ path: "/account/", query: { name: this.tabName } });
    },

    "$route.query": {
      immediate: true,
      handler() {
        this.setScreen();
      },
    },
  },

  mounted() {
    this.setScreen();
  },

  methods: {
    setScreen() {
      this.newRequest.active = false;
      this.requestDetails.active = false;

      if (typeof this.$route.query.new !== "undefined") {
        this.newRequest.increment_id = this.$route.query.new;
        this.newRequest.active = true;
      } else if (typeof this.$route.query.return !== "undefined") {
        this.requestDetails.request = this.$store.getters["rma/userRequests"];
        this.requestDetails.active = true;
      } else if (typeof this.$refs.returnOverview !== "undefined") {
        this.$refs.returnOverview.loadReturnRequest();
      }
    },

    addedReturnRequest(returnRequest) {
      this.requestDetails.request = returnRequest;
      this.requestDetails.active = true;

      this.newRequest.active = false;
      this.newRequest.increment_id = null;

      this.$refs.returnOverview.loadReturnRequest();
    },

    showRequestDetails(returnRequest) {
      this.requestDetails.request = returnRequest;
      this.requestDetails.active = true;
    },

    closeDetails() {
      this.requestDetails.active = false;
      this.requestDetails.request = null;
    },
  },
};
</script>
