<template>
  <div class="product-page-bottom">
    <ProductBottomLeft :selected-config-product="selectedConfigProduct" />
    <ProductBottomRight :selected-config-product="selectedConfigProduct" />
  </div>
</template>

<script>
import ProductBottomLeft from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductBottomLeft";
import ProductBottomRight from "@/esf_utrecht_bankxl/core/components/products/product-detail/ProductBottomRight";

export default {
  name: "ProductBottom",
  props: ["selectedConfigProduct"],
  components: {
    ProductBottomLeft,
    ProductBottomRight,
  },
};
</script>

<style>
</style>