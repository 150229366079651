<template>
  <div class="privacy-preferences">
    <h2>{{ $t("privacy_preferences") }}</h2>
    <div class="login-btn pt-30 mt-2">
      <div class="cookie-content">
        <h3>{{ $t("cookiebar_title") }}</h3>
        <p>
          {{ $t("cookiebar_text") }}
        </p>
        <div class="action-btn">
          <div>
            <div class="action-checkboxes-wrap">
              <div class="checkbox custom-checkbox green-tick disable">
                <input type="checkbox" checked disabled id="check1" />
                <label for="check1">{{ $t("cookiebar_necessary") }}</label>
              </div>
              <div class="checkbox custom-checkbox green-tick">
                <input type="checkbox" v-model="allCookies" id="check" />
                <label for="check">{{ $t("cookiebar_other") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button @click="onClick" variant="success">{{ $t("save") }}</b-button>
      <b-button @click="cancel" variant="cancel">{{ $t("back") }}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountPrivacyPreferences",
  data() {
    return {
      allCookies: this.isCookieAgreed ?? true,
    };
  },
  methods: {
    cancel() {
      this.$emit("showPrivacyPreferences", false);
    },
    submit() {
      this.$emit("showPrivacyPreferences", false);
    },
    onClick() {
      this.$cookies.config("180d");
      this.$cookies.set("websiteCookie", this.allCookies);
      if (this.allCookies == true) {
        this.$gtm.enable(true);
      }
    },
  },
  mounted() {
    var self = this;
    history.pushState({}, "");
    window.onpopstate = function (event) {
      event.preventDefault();
      self.$emit("showPrivacyPreferences", false);
    };
    window.scrollTo(0, 0);
  },
  computed: {
    isCookieAgreed: {
      get() {
        var cookie = this.$cookies.get("websiteCookie");
        switch (cookie) {
          case "false":
            return false;
          case "true":
          default:
            return true;
        }
      },
      set() {},
    },
  },
};
</script>
