<template>
  <div class="account-detail-tab-main">
    <MyAccountComponent class="" />
  </div>
</template>

<script>
import MyAccountComponent from "@/esf_utrecht_bankxl/core/components/account/MyAccountComponent.vue";
export default {
  name: "MyAccountPage",
  components: {
    MyAccountComponent,
  },
  metaInfo() {
    return {
      title: this.$t("my_account"),
    };
  },
};
</script>
