<template>
  <OrderComponent />
</template>
<script>
import OrderComponent from "@/esf_utrecht_bankxl/core/components/order/OrderComponent.vue";
import config from "@config";
export default {
  name: "OrderPage",
  components: {
    OrderComponent,
  },
  metaInfo() {
    return {
      title: this.$t("order"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
