<template>
  <BlogDetail />
</template>

<script>
import BlogDetail from "@/esf_utrecht_bankxl/core/components/blog/BlogDetail.vue";
export default {
  name: "BlogDetailView",
  components: {
    BlogDetail,
  },
};
</script>
