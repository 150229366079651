<template>
  <b-container>
    <div v-if="isOpen" class="cartOverlyPopup" @click.stop="close"></div>
    <div
      :class="['account-detail-tab-main-wrap', isOpen ? 'sidebar-visible' : '']"
    >
      <div v-if="isOpen" class="close-overlay" @click="toggleSidebar()"></div>
      <b-card no-body class="account-tabs border-0">
        <b-tabs vertical v-model="tabIndex">
          <b-tab
            @click="toggleSidebar('overview')"
            :title="$t('my_account_overview')"
            disabled
          >
            <!-- <b-card-text>
              <AccountTabOverview />
            </b-card-text> -->
          </b-tab>
          <b-tab
            @click="toggleSidebar('DataAndPreferences')"
            :title="$t('account_preferences_tab_title')"
          >
            <b-card-text class="mb-0">
              <AccountEmail v-if="isEditEmail == true" />
              <AccountInformation v-if="isEditUser == true" />
              <AccountPassword v-if="isEditPassword == true" />
              <AccountAddressBook v-if="isEditAddresses == true" />
              <AccountTabPreferences
                v-if="
                  isEditEmail == false &&
                  isEditPassword == false &&
                  isEditUser == false &&
                  isEditAddresses == false &&
                  isEditPaymentMethods == false &&
                  isEditPrivacyPreferences == false
                "
                v-on:showPrivacyPreferences="showPrivacyPreferences"
                v-on:showPaymentMethods="showPaymentMethods"
              />
              <AccountPrivacyPreferences
                v-if="isEditPrivacyPreferences == true"
                v-on:showPrivacyPreferences="showPrivacyPreferences"
              />
            </b-card-text>
          </b-tab>
          <b-tab @click="toggleSidebar('orders')" :title="$t('Orders')">
            <b-card-text class="mb-0">
              <AccountTabOrders />
            </b-card-text>
          </b-tab>
          <b-tab @click="toggleSidebar('invoices')" :title="$t('Invoices')">
            <b-card-text class="mb-0">
              <AccountTabInvoices />
            </b-card-text>
          </b-tab>
          <b-tab @click="toggleSidebar('returns')" :title="$t('Returns')">
            <b-card-text class="mb-0">
              <AccountTabReturns />
            </b-card-text>
          </b-tab>
          <b-tab @click="toggleSidebar('wishlist')" :title="$t('my_wishlist')">
            <b-card-text class="mb-0"><AccountTabWishlist /></b-card-text>
          </b-tab>
          <!-- <b-tab @click="toggleSidebar()" title="Offertes" > -->
          <!-- <b-card-text class="mb-0"><AccountTabOffers /></b-card-text> -->
          <!-- </b-tab> -->
          <b-tab
            @click="toggleSidebar('newsletters')"
            :title="$t('newsletters')"
          >
            <b-card-text class="mb-0"><AccountNewsLetter /></b-card-text>
          </b-tab>
          <b-tab @click="logout" :title="$t('logout')">
            <!-- <b-card-text>Tab contents 3</b-card-text> -->
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import AccountTabPreferences from "@/esf_utrecht_bankxl/core/components/account/AccountTabPreferences";
import AccountEmail from "@/esf_utrecht_bankxl/core/components/account/AccountEmail";
import AccountPassword from "@/esf_utrecht_bankxl/core/components/account/AccountPassword";
import AccountInformation from "@/esf_utrecht_bankxl/core/components/account/AccountInformation";
// import AccountTabOverview from "@/esf_utrecht_bankxl/core/components/account/AccountTabOverview";
import AccountTabOrders from "@/esf_utrecht_bankxl/core/components/account/AccountTabOrders";
import AccountTabInvoices from "@/esf_utrecht_bankxl/core/components/account/AccountTabInvoices";
import AccountTabWishlist from "@/esf_utrecht_bankxl/core/components/account/AccountTabWishlist";
// import AccountTabOffers from "@/esf_utrecht_bankxl/core/components/account/AccountTabOffers";
import AccountAddressBook from "@/esf_utrecht_bankxl/core/components/account/AccountAddressBook";
import AccountNewsLetter from "@/esf_utrecht_bankxl/core/components/account/AccountNewsLetter";
import AccountPrivacyPreferences from "@/esf_utrecht_bankxl/core/components/account/AccountPrivacyPreferences";
import AccountTabReturns from "@/esf_utrecht_bankxl/core/components/rma/user/Tab.vue";

// import $ from "jquery";

export default {
  name: "MyAccountComponent",
  props: {
    msg: { type: String, required: false },
  },
  components: {
    AccountTabPreferences,
    AccountEmail,
    AccountInformation,
    AccountPassword,
    // AccountTabOverview,
    AccountTabOrders,
    AccountTabInvoices,
    AccountTabWishlist,
    // AccountTabOffers,
    AccountAddressBook,
    AccountNewsLetter,
    AccountPrivacyPreferences,
    AccountTabReturns,
  },
  data() {
    return {
      isOpen: false,
      tabName: "",
      componentName: {
        // overview: 0,
        DataAndPreferences: 1,
        orders: 2,
        invoices: 3,
        returns: 4,
        wishlist: 5,
        newsletters: 6,
      },
      isEditPrivacyPreferences: false,
      isEditPaymentMethods: false,
    };
  },
  mounted() {
    this.$store.dispatch("user/loadOrders", { page: 1, perPage: 10 });
    this.$root.$on("toggleAccountSidebar", this.toggleSidebar);
  },

  methods: {
    toggleSidebar(name) {
      this.$store.commit("user/setEditUser", false);
      this.$store.commit("user/setEditPassword", false);
      this.$store.commit("user/setEditAddresses", false);
      this.$emit("showPrivacyPreferences", false);
      this.$root.$emit("switchTab");
      if (window.innerWidth <= 992) {
        this.isOpen = !this.isOpen;
        if (name === undefined) return;
      }
      this.tabName = name;
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.$store.commit("user/setAccountTabIndex", 0);
      this.$router.push("/");
    },
    close() {
      this.isOpen = false;
    },
    showPrivacyPreferences(value) {
      this.isEditPrivacyPreferences = value;
    },
    showPaymentMethods(value) {
      this.isEditPaymentMethods = value;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isEditEmail() {
      return this.$store.getters["user/getEditEmail"];
    },
    isEditUser() {
      return this.$store.getters["user/getEditUser"];
    },
    isEditPassword() {
      return this.$store.getters["user/getEditPassword"];
    },
    isEditAddresses() {
      return this.$store.getters["user/getEditAddresses"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
        window.scrollTo({
          top: 0,
        });
      },
    },
  },
  watch: {
    tabName() {
      this.$router.push({ path: "/account/", query: { name: this.tabName } });
    },

    "$route.query": {
      immediate: true,
      handler(v) {
        Object.keys(v).length === 0
          ? (this.tabIndex = 0)
          : (this.tabIndex = this.componentName[v.name]);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.close-overlay {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
</style>

