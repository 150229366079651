<template>
  <div class="tab-three tab-content-detail">
    <AccountTabOrdersDetail
      v-if="orderDetail && showOrderDetail"
      :order="orderDetail"
      @back="viewOrdersList"
    />
    <template v-else>
      <div class="heading bold pb-15">{{ $t("Orders") }}</div>
      <div v-if="!(showProducts && showProducts.length)">
        {{ $t("no_orders_available") }}
      </div>
      <template v-else>
        <div
          class="order-tab-block-main"
          v-for="order in showProducts"
          :key="order.id"
        >
          <div class="order-tab-block-heading pb-10">
            <span>
              {{ convertDate(order.order_date) }} | {{ $t("order_no") }}
              {{ order.number }}
            </span>
            <span>
              <b-link class="btn btn-success" @click="viewOrder(order.id)">{{
                $t("view_order")
              }}</b-link>
            </span>
          </div>
          <div
            class="order-tab-block mb-3"
            v-for="item in order.items"
            :key="item.id"
          >
            <div class="left-h">
              <b-img :src="item.image.url" alt="" v-if="item.image" />
            </div>
            <div class="left-r">
              <div class="product-acc-content">
                <h4
                  class="product_hd bold-l"
                  :class="order.status === 'Pending' ? 'red' : 'success'"
                >
                  {{ order.status }}
                </h4>
                <p>{{ item.product_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="heading bold pb-15 text-center return-item-btn"
          v-if="showLoadMore && showProducts"
        >
          <b-button class="green-btn-2ca show-more-btn" @click="loadMore">{{
            $t("show_more")
          }}</b-button>
        </div>
      </template>
      <div class="login-btn">
        <b-button variant="cancel" @click="goBack()">{{ $t("back") }}</b-button>
      </div>
    </template>
  </div>
</template>
<script>
import AccountTabOrdersDetail from "@/esf_utrecht_bankxl/core/components/account/AccountTabOrdersDetail.vue";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "AccountTabOrders",
  props: {
    msg: String,
  },
  components: {
    AccountTabOrdersDetail,
  },
  data() {
    return {
      step: 4,
      prodsCount: 4,
      orderDetail: null,
      showOrderDetail: true,
    };
  },
  computed: {
    myOrders() {
      return this.$store.getters["user/getMyOrders"];
    },
    showProducts() {
      return this.myOrders.slice(0, this.prodsCount);
    },
    showLoadMore() {
      return this.myOrders.length > this.prodsCount;
    },
    activeTab() {
      return this.$store.getters["user/getAccountTabIndex"];
    },
  },
  watch: {
    activeTab() {
      this.orderDetail = null;
      this.showOrderDetail = false;
    },
  },
  methods: {
    loadMore() {
      this.prodsCount += this.step;
    },
    viewOrder(orderId) {
      this.orderDetail = this.myOrders.find((order) => order.id === orderId);
      this.showOrderDetail = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    viewOrdersList() {
      this.showOrderDetail = false;
    },
    convertDate(date) {
      let month = [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ];
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },
    hideDetails() {
      this.orderDetail = null;
      this.showOrderDetail = false;
    },
    goBack() {
      this.$router.push({
        path: "/account/",
        query: { name: "DataAndPreferences" },
      });
    },
  },
  mounted() {
    this.$root.$on("switchTab", () => {
      Logger.debug("mounted", "AccountTabOrders", "switchTab")();
      this.hideDetails();
    });
  },
};
</script>
