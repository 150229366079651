<template>
  <div class="category-listing">
    <SearchSlider />
    <SearchPageContent />
    <CmsBlockSimple
      identifier="action_banner_search_page"
      class="action-banner"
    />
  </div>
</template>

<script>
import SearchPageContent from "@/esf_utrecht_bankxl/core/components/search/SearchPageContent";
import SearchSlider from "@/esf_utrecht_bankxl/core/components/search//SearchSlider.vue";
import CmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";

export default {
  name: "ProductsListingSearch",
  components: {
    SearchPageContent,
    SearchSlider,
    CmsBlockSimple,
  },
  computed: {},
};
</script>
