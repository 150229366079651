<template>
  <b-modal
    id="contact-modal"
    class="product-modal-inner"
    content-class="defaul-modal"
    hide-footer
    centered
    size="lg"
  >
    <template #modal-title>{{ $t("confirmation_sent_message") }}</template>
    <div class="modal-text d-block border-bottom">
      <p>
        {{ $t("confirmation_text") }}
      </p>
    </div>
    <div class="modal-btn-box pt-20">
      <b-button
        class="btn-success"
        block
        @click="$bvModal.hide('contact-modal')"
      >
        {{ $t("continue_shopping") }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ContactModalComponent",
  data() {
    return {};
  },
};
//contact-modal
</script>