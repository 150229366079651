<template>
  <div class="">
    <b-container>
      <b-row>
        <b-col>
          <cmsBlockSimple
            v-if="typeof contentPage == 'object'"
            :identifier="contentPage.identifier"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import garntee from "@/esf_utrecht_bankxl/assets/images/garntee.png";
import sittingImage from "@/esf_utrecht_bankxl/assets/images/sitting1.png";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
export default {
  name: "HerolistComponent",
  components: { cmsBlockSimple },
  props: {
    msg: String,
  },
  data() {
    return {
      garntee,
      sittingImage,
    };
  },
  computed: {
    contentPage() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "content_page"
      );
    },
  },
};
</script>
