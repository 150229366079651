<template>
  <div class="cart-bottom">
    <cmsBlockSimple
      v-if="typeof bottomCMSBlock == 'object'"
      :identifier="bottomCMSBlock.identifier"
    />
  </div>
</template>
<script>
import testimonialImg from "@/esf_utrecht_bankxl/assets/images/testimonial.png";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "ShoppingTestimonialComponent",
  components: {
    cmsBlockSimple,
  },
  data() {
    return {
      testimonialImg,
    };
  },
  computed: {
    bottomCMSBlock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "cart_bottom_content"
      );
    },
  },
};
</script>