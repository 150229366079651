<template>
  <div class="category_slider" v-if="search_slider && search_slider.slides">
    <h3>
      {{ $t("read_more_about_slider") }}
    </h3>
    <VueSlickCarousel
      v-if="search_slider && search_slider.slides.length"
      :arrows="true"
      :dots="false"
      :slidesToShow="5"
      :slidesToScroll="4"
      :autoplay="false"
      :center="false"
      :responsive="[
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ]"
    >
      <div
        class="cms-block-content"
        v-for="(slide, index) in search_slider.slides"
        :key="index"
      >
        <div>
          <b-card class="home-category-card">
            <router-link :to="slide.link">
              <div class="card-img-top-box">
                <b-img
                  class="card-img-top"
                  :src="slide.media.url"
                  :alt="slide.title"
                />
              </div>
              <div class="card-body-custom text-center">
                <div v-html="slide.title" class="card-title"></div>
              </div>
            </router-link>
          </b-card>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import { isMobile, isTablet } from "mobile-device-detect";

export default {
  name: "SearchSlider",
  components: { VueSlickCarousel },
  data() {
    return {
      noOfSlidePerPage: 5,
    };
  },
  computed: {
    search_slider() {
      var data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "lees_meer_search"
        );
      if (data) {
        return data;
      }
      data = {
        slides: false,
      };
      return data;
    },
  },
  methods: {
    setNoOfSlidePerPage() {
      if (isMobile && !isTablet) {
        this.noOfSlidePerPage = 2;
      } else if (isTablet) {
        this.noOfSlidePerPage = 3;
      } else {
        this.noOfSlidePerPage = 5;
      }
    },
  },
  mounted() {
    this.setNoOfSlidePerPage();
  },
};
</script>
