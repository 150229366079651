<template>
  <div class="account-informate login-form border">
    <div class="text-16 pb-10 mb-4 border-bottom mob-text-cen">
      <span><font-awesome-icon icon="user" /></span>
      {{ $t("change_email_info") }}
    </div>
    <b-row>
      <b-col md="12">
        <b-form-group
          class="account-inputs"
          id="email-group"
          :label="$t('form_email') + '*'"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="text"
            :state="form.emailState"
            aria-describedby="email-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-feedback">
            {{ form.emailError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            :state="form.passwordState"
            aria-describedby="password-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button @click="cancel" variant="cancel">{{ $t("back") }}</b-button>
      <b-button @click="submit" variant="success">{{ $t("save") }}</b-button>
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";

export default {
  name: "AccountEmail",
  components: {
    //passwordMeter,
  },
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      form: {
        email: "",
        emailState: null,
        emailError: "",
        password: "",
        passwordState: null,
        passwordError: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    user() {
      this.form.email = this.user.email;
    },
  },
  mounted() {
    this.form.email = this.user.email;
    history.pushState({}, "");
    var self = this;
    window.onpopstate = function (event) {
      event.preventDefault();
      self.$store.commit("user/setEditEmail", false);
    };
  },
  methods: {
    validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.form.emailState = true;
      return true;
    },

    async submit() {
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.emailState != true) {
          formValid = false;
        }
      }
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.passwordState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        const retval = await this.$store.dispatch("user/updateAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$store.commit("user/setEditEmail", false);
        } else {
          const msg = {
            type: "failure",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          // this.$store.commit("user/setEditEmail", false);
        }
      }
    },
    cancel() {
      this.$store.commit("user/setEditEmail", false);
    },
    validatePassword() {
      if (this.form.password == "") {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_is_required_field");
      } else {
        this.form.passwordState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-informate {
  float: left;
}
</style>
