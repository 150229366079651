<template>
  <div class="tab-content-detail">
    <div class="account-newsletter">
      <div class="heading mb-head-5">{{ $t("newsletters") }}</div>
      <b-row>
        <b-col sm="12">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            label-for="checkbox1"
            v-model="is_subscribed"
            class="pl-0"
          >
            {{ $t("newsletter_subscription_selected") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <div class="login-btn pt-30 text-center mt-2">
        <b-button @click="save" variant="success">{{ $t("save") }}</b-button>
        <b-button @click="goBack" variant="cancel">{{ $t("back") }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "AccountNewsLetter",
  data: () => ({
    is_subscribed: false,
  }),
  mounted() {
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    user() {
      this.is_subscribed = this.user.is_subscribed;
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/account/",
        query: { name: "DataAndPreferences" },
      });
    },
    async save() {
      const retval = await this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });

      if (retval == true) {
        let msgText = "";
        if (this.is_subscribed == true) {
          msgText = this.$t("subscription_succesful");
        } else {
          msgText = this.$t("unsubscription_succesful");
        }
        const msg = {
          type: "success",
          title: "",
          text: msgText,
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>

<style>
</style>
