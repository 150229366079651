<template>
  <section class="rma-detail">
    <b-container>
      <h2 v-if="returnOrder">
        {{
          $t("Return Request {returnNumber} for order {orderNumber}", {
            returnNumber: returnOrder.number,
            orderNumber: returnOrder.order.number,
          })
        }}
      </h2>

      <div class="status request-detail-item" v-if="returnOrder.status">
        <strong>{{ $t("rma_status") }}</strong> {{ returnOrder.status }}
      </div>
      <div
        class="request-date request-detail-item"
        v-if="returnOrder.request_date"
      >
        <strong>{{ $t("rma_request_date") }}</strong>
        {{ formatDateTime(returnOrder.request_date) }}
      </div>
      <div
        class="request-comments request-detail-item"
        v-if="returnOrder.comment"
      >
        <strong>{{ $t("Comments") }}:</strong> {{ returnOrder.comment }}
      </div>

      <div
        class="alert alert-info rma-alert-info mt-4"
        v-if="returnOrder.return_label.payment_needed"
      >
        <div class="icon fs-2">
          <i class="fa-solid fa-circle-info fa-xl"></i>
        </div>
        <div class="message">
          <p>{{ $t("rma_payment_advisory") }}</p>
          <div>
            <b-link
              class="btn btn-primary"
              :to="{
                path: '/returns/order/payment/',
                query: { return: returnOrder.id },
              }"
              >{{ $t("rma_payment_advisory_button") }}</b-link
            >
          </div>
        </div>
      </div>

      <div
        class="mt-4 labels-wrapper"
        v-if="returnOrder.return_label.items.length"
      >
        <h3>{{ $t("rma_request_labels_header") }}</h3>

        <div class="return-labels">
          <div
            class="return-label-wrapper"
            v-for="(label, index) in returnOrder.return_label.items"
            :key="index"
          >
            <div class="return-label">
              <div class="carrier data-row">
                <div class="data-label">{{ $t("rma_tracking_carrier") }}</div>
                <div class="data-value">{{ label.title }}</div>
              </div>
              <div class="carrier data-row">
                <div class="data-label">{{ $t("rma_tracking_code") }}</div>
                <div class="data-value">
                  <template v-if="label.tracking_url">
                    <a :href="label.tracking_url" target="_blank">{{
                      label.tracking_code
                    }}</a>
                  </template>
                  <template v-else>
                    {{ label.tracking_code }}
                  </template>
                </div>
              </div>
              <div class="action">
                <a
                  :href="label.label_url"
                  target="_blank"
                  class="btn btn-success"
                >
                  <i class="fa-solid fa-download"></i>
                  {{ $t("rma_tracking_download_label") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 items-table-wrapper">
        <h3>{{ $t("rma_request_items_header") }}</h3>

        <div class="table-responsive d-none d-xl-block">
          <table class="table items-table table-striped">
            <colgroup>
              <col />
              <col width="100px" />
              <col width="175px" />
              <col width="150px" />
              <col width="250px" />
              <col width="250px" />
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t("Item") }}</th>
                <th class="text-center">{{ $t("Quantity") }}</th>
                <th>{{ $t("Reason") }}</th>
                <th>{{ $t("Desired Action") }}</th>
                <th>{{ $t("Comments") }}</th>
                <th>{{ $t("Attachments") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in returnOrder.items" :key="item.id">
                <td class="item-header">
                  <div class="item-details">
                    <div class="item-image">
                      <img
                        v-if="
                          item.order_item.image && item.order_item.image.small
                        "
                        :src="item.order_item.image.small"
                        :alt="item.order_item.product_name"
                      />
                    </div>
                    <div class="item-title">
                      <b-link
                        :to="'/' + item.order_item.product_url_key + '/'"
                        >{{ item.order_item.product_name }}</b-link
                      ><br />
                      <small class="text-sm text-muted">{{
                        item.order_item.product_sku
                      }}</small>
                    </div>
                  </div>
                </td>
                <td class="text-center">{{ item.quantity }}</td>
                <td>{{ item.reason }}</td>
                <td>{{ item.action }}</td>
                <td>{{ item.comment }}</td>
                <td>
                  <div
                    class="attachment"
                    v-for="(attachment, index) in item.files"
                    :key="index"
                  >
                    <a :href="attachment.url" target="_blank">{{
                      attachment.name
                    }}</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-block d-xl-none items-mobile">
          <div class="item" v-for="item in returnOrder.items" :key="item.id">
            <div class="item-image">
              <img
                v-if="item.order_item.image && item.order_item.image.small"
                :src="item.order_item.image.small"
                :alt="item.order_item.product_name"
              />
            </div>
            <div class="item-details">
              <div class="item-title">
                <b-link :to="'/' + item.order_item.product_url_key + '/'">{{
                  item.order_item.product_name
                }}</b-link
                ><br />
                <small class="text-sm text-muted">{{
                  item.order_item.product_sku
                }}</small>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Quantity") }}</strong>
                <span>{{ item.quantity }}</span>
              </div>
              <div class="item-reason item-detail">
                <strong>{{ $t("Reason") }}</strong>
                <span>{{ item.reason }}</span>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Desired Action") }}</strong>
                <span>{{ item.action }}</span>
              </div>

              <div class="item-comment item-detail">
                <strong>{{ $t("Comments") }}</strong>
                <span>{{ item.comment }}</span>
              </div>

              <div class="item-attachments item-detail">
                <strong>{{ $t("Attachments") }}</strong>
                <span class="attachments">
                  <div
                    class="attachment"
                    v-for="(attachment, index) in item.files"
                    :key="index"
                  >
                    <a :href="attachment.url" target="_blank">{{
                      attachment.name
                    }}</a>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div class="item" v-for="item in returnOrder.items" :key="item.id">
            <div class="item-image">
              <img
                v-if="item.order_item.image && item.order_item.image.small"
                :src="item.order_item.image.small"
                :alt="item.order_item.product_name"
              />
            </div>
            <div class="item-details">
              <div class="item-title">
                <b-link :to="'/' + item.order_item.product_url_key + '/'">{{
                  item.order_item.product_name
                }}</b-link
                ><br />
                <small class="text-sm text-muted">{{
                  item.order_item.product_sku
                }}</small>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Quantity") }}</strong>
                <span>{{ item.quantity }}</span>
              </div>
              <div class="item-reason item-detail">
                <strong>{{ $t("Reason") }}</strong>
                <span>{{ item.reason }}</span>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Desired Action") }}</strong>
                <span>{{ item.action }}</span>
              </div>

              <div class="item-comment item-detail">
                <strong>{{ $t("Comments") }}</strong>
                <span>{{ item.comment }}</span>
              </div>

              <div class="item-attachments item-detail">
                <strong>{{ $t("Attachments") }}</strong>
                <span class="attachments">
                  <div
                    class="attachment"
                    v-for="(attachment, index) in item.files"
                    :key="index"
                  >
                    <a :href="attachment.url" target="_blank">{{
                      attachment.name
                    }}</a>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div class="item" v-for="item in returnOrder.items" :key="item.id">
            <div class="item-image">
              <img
                v-if="item.order_item.image && item.order_item.image.small"
                :src="item.order_item.image.small"
                :alt="item.order_item.product_name"
              />
            </div>
            <div class="item-details">
              <div class="item-title">
                <b-link :to="'/' + item.order_item.product_url_key + '/'">{{
                  item.order_item.product_name
                }}</b-link
                ><br />
                <small class="text-sm text-muted">{{
                  item.order_item.product_sku
                }}</small>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Quantity") }}</strong>
                <span>{{ item.quantity }}</span>
              </div>
              <div class="item-reason item-detail">
                <strong>{{ $t("Reason") }}</strong>
                <span>{{ item.reason }}</span>
              </div>
              <div class="item-quantity item-detail">
                <strong>{{ $t("Desired Action") }}</strong>
                <span>{{ item.action }}</span>
              </div>

              <div class="item-comment item-detail">
                <strong>{{ $t("Comments") }}</strong>
                <span>{{ item.comment }}</span>
              </div>

              <div class="item-attachments item-detail">
                <strong>{{ $t("Attachments") }}</strong>
                <span class="attachments">
                  <div
                    class="attachment"
                    v-for="(attachment, index) in item.files"
                    :key="index"
                  >
                    <a :href="attachment.url" target="_blank">{{
                      attachment.name
                    }}</a>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-detail-footer login-btn">
        <b-button variant="cancel" @click="$emit('back')">{{
          $t("back")
        }}</b-button>
      </div>
    </b-container>
  </section>
</template>

<script>
import config from "@config";

export default {
  props: {
    returnOrder: {
      type: Object,
      required: true,
    },
  },

  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: "Retour #" + this.returnOrder.number,
        },
        {
          name: "keywords",
          content: "",
        },
        {
          name: "description",
          content: "",
        },
      ],
      title: "Retour #" + this.returnOrder.number,
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  data() {
    return {
      dateFormat: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      },
    };
  },

  mounted() {
    const bcrumb = {
      current: "Retour #" + this.returnOrder.number,
      routes: [],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  methods: {
    formatDateTime(datetime) {
      let formattedDate = new Date(datetime).toLocaleDateString(
        "nl-NL",
        this.dateFormat
      );
      return formattedDate;
    },
  },
};
</script>
