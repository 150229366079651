<template>
  <div class="product-page-bottom-right">
    <div class="product-content right" v-if="related && related.length">
      <h3 class="product-heading category-heading">
        {{
          $t("these_products_belong_to", {
            product_type: productSoort,
          })
        }}
      </h3>
      <div class="product-carousel-main related-products">
        <CarouselComponent
          v-bind:productData="related"
          v-bind:settings="CAROUSEL_SETTING_FIFTH"
        />
      </div>
    </div>

    <div v-if="serie && serie.length" class="product-content right">
      <h3 class="product-heading category-heading">
        {{ $t("from_the_same_serie") }}
      </h3>
      <div class="product-carousel-main related-products">
        <CarouselComponent
          v-bind:productData="serie"
          v-bind:settings="CAROUSEL_SETTING_FIFTH"
        />
      </div>
    </div>

    <div
      v-if="upsell && upsell.length"
      class="product-content right"
      id="other-product"
    >
      <h3 class="product-heading category-heading">
        {{ $t("other") }} {{ productSoort }}
      </h3>
      <div class="product-carousel-main">
        <CarouselComponent
          v-bind:productData="upsell"
          v-bind:settings="CAROUSEL_SETTING_FIFTH"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CarouselComponent from "@/esf_utrecht_bankxl/core/components/core/CarouselComponent";
import { CAROUSEL_SETTING_FIFTH } from "@/esf_utrecht_bankxl/assets/constant.js";

export default {
  name: "ProductBottomRight",
  components: {
    CarouselComponent,
  },
  props: ["selectedConfigProduct"],
  data() {
    return { CAROUSEL_SETTING_FIFTH };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
      serie: "productSerie/getSerie",
      upsell: "product/getUpSell",
    }),
    specialProducts() {
      return this.$store.getters["home/getSpecialSelectedProducts"];
    },
    productSoort() {
      let product = this.selectedConfigProduct
        ? this.selectedConfigProduct
        : this.product;
      let productSoort = "";
      product.information_attributes.forEach((attribute) => {
        if (attribute.attribute_code == "soort") {
          productSoort = attribute.value;
        }
      });
      return productSoort;
    },
  },
};
</script>

<style>
</style>
