<template>
  <b-form-input
    ref="inputOne"
    :id="id"
    v-if="isToggleInput"
    v-model="inputData"
    :type="inputType"
    :state="isInputValid"
    @input="validation($event)"
    :aria-describedby="ariaOfInput"
    :placeholder="inputPlaceHolder"
  ></b-form-input>
  <input
    v-else
    id="secondField"
    ref="inputSecond"
    v-model="inputData"
    :type="inputType"
    @input="validation($event.target.value)"
    class="form-control"
    :placeholder="inputPlaceHolder"
  />
</template>
<script>
export default {
  name: "inputFiled",
  emit: ["setValue"],
  props: {
    id: {
      type: String,
    },
    inputValue: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    inputPlaceHolder: {
      type: String,
      default: "",
    },
    ariaOfInput: {
      type: String,
      default: "",
    },
    isInputValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputData: JSON.parse(JSON.stringify(this.inputValue)),
      isToggleInput: true,
      timer: null,
      cursorPosition: 0,
    };
  },
  methods: {
    validation(value) {
      this.$emit("setValue", value);
    },
    setCaretPosition(ctrl, pos) {
      ctrl.focus();
      ctrl.selectionStart = ctrl.selectionEnd = pos;
    },
    setCurrentCursorPosition() {
      if (this.$refs.inputOne && this.$refs.inputOne.selectionStart) {
        this.cursorPosition = this.$refs.inputOne.selectionStart;
      }
      if (this.$refs.inputSecond && this.$refs.inputSecond.selectionStart) {
        this.cursorPosition = this.$refs.inputSecond.selectionStart;
      }
    },
  },
  watch: {
    isToggleInput(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.setCaretPosition(this.$refs.inputOne, this.cursorPosition);
        });
      } else {
        this.$nextTick(() => {
          this.setCaretPosition(this.$refs.inputSecond, this.cursorPosition);
        });
      }
    },
    inputData() {
      this.isToggleInput = false;
      this.setCurrentCursorPosition();
      this.$emit("setValue", this.inputData);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isToggleInput = true;
      }, 400);
    },
  },
};
</script>
