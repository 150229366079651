<template>
  <div class="account-informate login-form border">
    <div class="text-16 pb-10 mb-4 border-bottom mob-text-cen">
      <span><font-awesome-icon icon="user" /></span>
      {{ $t("change_password") }}
    </div>

    <b-row>
      <b-col md="12">
        <b-form-group
          class="account-inputs password-watch"
          id="password-group"
          :label="$t('form_current_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="current_password"
            v-model="form.current_password"
            :type="form.passwordFieldType.current_password"
            :state="form.current_passwordState"
            aria-describedby="current_password-feedback"
          ></b-form-input>
          <span class="show-password">
            <b-link
              class="span-txt"
              @click="toggleShowPass($event, 'current_password')"
            >
              {{
                form.passwordFieldType.current_password === "password"
                  ? $t("show")
                  : $t("hide")
              }}
            </b-link>
          </span>
          <b-form-invalid-feedback id="current_password-feedback">
            {{ form.current_passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs password-watch"
          id="password-group"
          :label="$t('form_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            :type="form.passwordFieldType.password"
            :state="form.passwordState"
            aria-describedby="password-feedback"
          ></b-form-input>
          <span class="show-password">
            <b-link
              class="span-txt"
              @click="toggleShowPass($event, 'password')"
            >
              {{
                form.passwordFieldType.password === "password"
                  ? $t("show")
                  : $t("hide")
              }}
            </b-link>
          </span>
          <client-only>
            <password-meter :password="form.password" />
          </client-only>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs password-watch"
          id="password_confirm-group"
          :label="$t('form_password_confirmation') + '*'"
          label-for="password_confirm"
        >
          <b-form-input
            id="password_confirm"
            v-model="form.password_confirm"
            :type="form.passwordFieldType.password_confirm"
            :state="form.password_confirmState"
            aria-describedby="password_confirm-feedback"
          ></b-form-input>
          <span class="show-password">
            <b-link
              class="span-txt"
              @click="toggleShowPass($event, 'password_confirm')"
            >
              {{
                form.passwordFieldType.password_confirm === "password"
                  ? $t("show")
                  : $t("hide")
              }}
            </b-link>
          </span>
          <b-form-invalid-feedback id="password_confirm-feedback">
            {{ form.password_confirmError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button @click="submit" variant="success">{{ $t("save") }}</b-button>
      <b-button @click="cancel" variant="cancel">{{ $t("back") }}</b-button>
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  name: "AccountPassword",
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
    //passwordMeter,
  },
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      form: {
        email: "",
        emailState: null,
        emailError: "",
        current_password: "",
        current_passwordState: null,
        current_passwordError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        passwordFieldType: {
          password_confirm: "password",
          password: "password",
          current_password: "password",
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
  },
  watch: {
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.form.current_passwordState != true) {
        this.validateCurrentPassword();
        if (this.current_passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        const retval = await this.$store.dispatch("user/changePassword", {
          currentPassword: this.form.current_password,
          newPassword: this.form.password,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$store.commit("user/setEditPassword", false);
        } else {
          const msg = {
            type: "failure",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
    cancel() {
      this.$store.commit("user/setEditPassword", false);
    },

    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_to_short");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password_confirm.length < 8) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_to_short");
      } else if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },

    validateCurrentPassword() {
      if (this.form.current_password == "") {
        this.form.current_passwordState = false;
        this.form.current_passwordError = this.$t("password_is_required_field");
      } else {
        this.form.current_passwordState = true;
      }
    },
    toggleShowPass(event, field) {
      switch (field) {
        case "current_password":
          this.form.passwordFieldType.current_password =
            this.form.passwordFieldType.current_password === "password"
              ? "text"
              : "password";
          break;
        case "password":
          this.form.passwordFieldType.password =
            this.form.passwordFieldType.password === "password"
              ? "text"
              : "password";
          break;
        case "password_confirm":
          this.form.passwordFieldType.password_confirm =
            this.form.passwordFieldType.password_confirm === "password"
              ? "text"
              : "password";
          break;
      }
    },
  },
  mounted() {
    var self = this;
    history.pushState({}, "");
    window.onpopstate = function (event) {
      event.preventDefault();
      self.$store.commit("user/setEditPassword", false);
    };
    document.documentElement.scrollTo({ top: 0 });
  },
};
</script>
<style lang="scss" scoped>
.account-informate {
  float: left;

  &.login-form {
    @include phone-xl {
      padding: 15px;
    }

    .account-inputs {
      @include phone-xl {
        ::v-deep label {
          font-size: 14px;
        }
      }
    }

    input {
      height: 40px;
      margin-bottom: 0;
      padding: 6px 20px;
    }

    .login-btn {
      @include phone-xl {
        padding-top: 0 !important;
      }
      .btn {
        @include phone-xl {
          width: 100%;
          margin-left: 0;
          font-size: 16px;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    .border-bottom {
      @include phone-xl {
        font-size: 16px;
      }
    }
  }
}
</style>

