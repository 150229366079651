<template>
  <ContactComponent />
</template>

<script>
import ContactComponent from "@/esf_utrecht_bankxl/core/components/contact/ContactComponent.vue";
import config from "@config";
export default {
  name: "ContactPage",
  components: {
    ContactComponent,
  },
  metaInfo() {
    return {
      title: this.$t("contact_us"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
