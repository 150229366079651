<template>
  <BlogList />
</template>

<script>
import BlogList from "@/esf_utrecht_bankxl/core/components/blog/BlogList.vue";
export default {
  name: "BlogListView",
  components: {
    BlogList,
  },
};
</script>
