<template>
  <div class="container">
    <div class="top-padding search-page">
      <Breadcrumbs />
      <b-row>
        <b-col lg="3">
          <SearchSidebarComponent :isActive="isSidebarOpen" />
        </b-col>
        <b-col lg="9">
          <SearchDescription
            :searchSuggestions="searchSuggestions"
            :searchRecommendations="searchRecommendations"
          />
          <ProductsListingSearch />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import config from "@config";
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
// import SearchProductFilter from "@/esf_utrecht_bankxl/core/components/search/SearchProductFilter";
import SearchDescription from "@/esf_utrecht_bankxl/core/components/search/SearchDescription";
import ProductsListingSearch from "@/esf_utrecht_bankxl/core/components/search/ProductsListingSearch";
import SearchSidebarComponent from "@/esf_utrecht_bankxl/core/components/search/SearchSidebarComponent";
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { extractFilters } from "@storefront/core/router/helpers/cleanPath.js";
import config from "@config";

export default {
  name: "SearchPage",
  components: {
    Breadcrumbs,
    // SearchProductFilter,
    SearchSidebarComponent,
    SearchDescription,
    ProductsListingSearch,
  },
  metaInfo() {
    return {
      title: this.$t("search"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },

  data: () => ({
    isSidebarOpen: false,
    searchSuggestions: [],
    searchRecommendations: [],
    closeSignal: {},
  }),
  provide() {
    return {
      openSearchSidebar: this.openSidebar,
      closeSearchSidebar: this.closeSidebar,
    };
  },
  async mounted() {
    const searchVal = this.$route.query.q;
    await this.loadSearch(searchVal);
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchWord(searchVal) {
      this.loadSearch(searchVal);
      // const { path } = this.$route;
      // const query = { ...this.$route.query };
      // const pageSize = config.products?.perPage;
      // const perPage = this.$store.getters["search/getProductsPerPage"];

      // if (perPage != pageSize) query["page-size"] = perPage;
      // this.$router.push({ path: path, query: query });
    },
  },
  methods: {
    async loadSearch(searchVal) {
      if (searchVal != null && searchVal != "") {
        const controller = new AbortController();
        const { signal } = controller;
        this.closeSignal.products = controller;
        const output = await getProductsBySearchPreview(searchVal, signal, 10);
        this.closeSignal.products = null;
        if (output.search_suggestions)
          this.searchSuggestions = output.search_suggestions;
        else this.searchSuggestions = [];

        if (output.searchRecommendations)
          this.searchRecommendations = output.search_recommendations;
        else this.searchRecommendations = [];

        const filter = extractFilters(this.$route);
        await this.$store.dispatch("search/load", {
          search: searchVal,
          filter,
        });
        const bcrumb = { current: searchVal, routes: [] };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
    openSidebar() {
      this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
  },
};
</script>
