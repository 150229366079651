<template>
  <div class="faq-page-main">
    <b-container>
      <div class="faq-page pt-40 mb-50">
        <div class="faq-inner">
          <div class="faq-left">
            <div class="faq-top-content pb-40 border-bottom">
              <h3 class="category-heading border-none pb-30">
                {{ $t("how_can_we_help") }}
              </h3>
              <p v-html="$t('for_help_mail_or_call')"></p>
            </div>
            <ul class="faq-list">
              <li v-for="item in faq" v-bind:key="`cat_` + item.category_id">
                <b-link @click="scrollTo('cat_' + item.category_id)">
                  <span class="faq-list-icon"
                    ><font-awesome-icon icon="caret-right"
                  /></span>
                  {{ item.title }}</b-link
                >
              </li>
            </ul>
          </div>
          <div class="faq-right">
            <!--  -->
            <!--  -->
            <h3 class="category-heading border-none pb-20 mobile-title">
              {{ $t("faq_title") }}
            </h3>
            <div
              class="faq-acc-first"
              v-for="item in faq"
              v-bind:key="`cat_det_` + item.category_id"
              :ref="`cat_` + item.category_id"
              :id="`cat_` + item.category_id"
            >
              <h4 class="faq-heading-list">
                {{ item.title }}
              </h4>
              <div class="faq-accordian accordion" role="tablist">
                <b-card
                  no-body
                  class=""
                  v-for="(question, index) in item.faq.items"
                  v-bind:key="`question_` + item.category_id + `_` + index"
                >
                  <b-card-header header-tag="header" class="" role="tab">
                    <b-button
                      block
                      v-b-toggle="+item.category_id + `_` + index"
                      variant="white"
                      ><span class="faq-list-icon">
                        <font-awesome-icon icon="chevron-right"
                      /></span>
                      {{ question.question }}
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    :id="+item.category_id + `_` + index"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text v-html="question.long_answer" />
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "FaqComponent",
  computed: {
    faq() {
      return this.$store.getters["faq/getFaq"];
    },
  },
  methods: {
    scrollTo(refName) {
      var top = this.$refs[refName][0].offsetTop;
      window.scrollTo(0, top - 210);
    },
  },
};
</script>
