<template>
  <div class="account-informate login-form border">
    <div class="text-16 pb-10 mb-4 border-bottom mob-text-cen">
      <span><font-awesome-icon icon="user" /></span>
      {{ $t("personal_information") }}
    </div>
    <b-row>
      <b-col md="12">
        <b-form-group
          class="account-inputs"
          id="first-name-group"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.firstname"
            type="text"
            v-on:blur="validateFirstname()"
            :state="form.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last-name-group"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.lastname"
            type="text"
            v-on:blur="validateLastname()"
            :state="form.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="email-group"
          :label="$t('email') + '*'"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="text"
            v-on:blur="validateEmail()"
            :state="form.emailState"
            aria-describedby="email-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-feedback">
            {{ form.emailError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button @click="submit" variant="success">{{ $t("save") }}</b-button>
      <b-button @click="cancel" variant="cancel">{{ $t("back") }}</b-button>
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";
import { checkEmailExist } from "@storefront/core/data-resolver/user";
export default {
  name: "AccountInformation",
  components: {
    //passwordMeter,
  },
  data() {
    return {
      form: {
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        emailname: "",
        emailState: null,
        emailError: "",
        originalEmail: "",
      },
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    user() {
      this.form.firstname = this.user.firstname;
      this.form.lastname = this.user.lastname;
      this.form.email = this.user.email;
      this.form.originalEmail = this.user.email;
    },
  },
  mounted() {
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
    this.form.email = this.user.email;
    this.form.originalEmail = this.user.email;
    var self = this;
    history.pushState({}, "");
    window.onpopstate = function () {
      self.$store.commit("user/setEditUser", false);
    };
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.form.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.form.lastnameState != true) {
          formValid = false;
        }
      }
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        const retval = await this.$store.dispatch("user/updateAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$store.commit("user/setEditUser", false);
        } else {
          const msg = {
            type: "failure",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$store.commit("user/setEditUser", false);
        }
      }
    },
    cancel() {
      this.$store.commit("user/setEditUser", false);
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    async validateEmail() {
      if (this.form.originalEmail == this.form.email) {
        this.form.emailState = true;
        return true;
      }
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.form.emailExists = true;
        this.form.emailState = false;
        this.form.emailError = this.$t("email_already_exists");
        return false;
      } else {
        this.emailExists = false;
      }
      this.form.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-informate {
  float: left;

  &.login-form {
    @include phone-xl {
      padding: 15px;
    }

    .account-inputs {
      @include phone-xl {
        ::v-deep label {
          font-size: 14px;
        }
      }
    }

    input {
      height: 40px;
      margin-bottom: 20px;
      padding: 6px 20px;
    }

    .login-btn {
      @include phone-xl {
        padding-top: 0 !important;
      }
      .btn {
        @include phone-xl {
          width: 100%;
          margin-left: 0;
          font-size: 16px;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    .border-bottom {
      @include phone-xl {
        font-size: 16px;
      }
    }
  }
}
</style>
