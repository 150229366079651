<template>
  <div class="rma-page rma-user-returns-overview">
    <div class="heading pb-15">
      <div class="heading-text bold">{{ $t("Returns") }}</div>
    </div>

    <div class="tabs">
      <div class="tab-headers">
        <div
          class="tab-header"
          id="my-returns"
          :class="{ active: activeTab === 'my-returns' }"
          @click.prevent="activeTab = 'my-returns'"
        >
          {{ $t("rma_my_returns") }}
          <span class="count">({{ returnOrders.length }})</span>
        </div>
        <div
          class="tab-header"
          id="new-return"
          :class="{ active: activeTab === 'new-return' }"
          @click.prevent="activeTab = 'new-return'"
        >
          {{ $t("rma_user_new_return") }}
        </div>
      </div>
      <div class="tab-contents">
        <div class="tab-content returns" v-show="activeTab === 'my-returns'">
          <template v-if="returnOrders.length > 0">
            <div
              class="return"
              v-for="returnOrder in returnOrders"
              :key="returnOrder.id"
            >
              <div class="return-item-header">
                <div class="title">
                  {{
                    $t("rma_return_overview_item_title", {
                      returnNumber: returnOrder.number,
                      orderNumber: returnOrder.order.number,
                    })
                  }}
                </div>
                <div class="subtitle">
                  <small class="text-muted">{{
                    $t("rma_return_overview_item_subtitle", {
                      dateTime: formatDateTime(returnOrder.request_date),
                    })
                  }}</small>
                </div>
              </div>
              <div class="return-item-body">
                <div class="return-items">
                  <div
                    class="return-item"
                    v-for="item in returnOrder.items"
                    :key="item.id"
                  >
                    {{ item.quantity }} x
                    <b-link :to="'/' + item.order_item.product_url_key + '/'">{{
                      item.order_item.product_name
                    }}</b-link>
                  </div>
                </div>
              </div>

              <div class="return-item-status">
                <span class="status-text">{{ returnOrder.status }}</span>
              </div>
              <div class="return-item-actions">
                <b-link
                  class="btn btn-success"
                  @click="viewRequest(returnOrder)"
                  >{{ $t("rma_view_request_details") }}</b-link
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center mt-5 text-muted no-returns">
              {{ $t("rma_no_returns") }}
              <div class="mt-5">
                <b-link
                  class="btn btn-success"
                  @click.prevent="activeTab = 'new-return'"
                  >{{ $t("rma_user_new_request_button") }}</b-link
                >
              </div>
            </div>
          </template>
        </div>

        <div class="tab-content new-return" v-show="activeTab === 'new-return'">
          <StartRequest />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import StartRequest from "./StartRequest.vue";

export default {
  data() {
    return {
      activeTab: "my-returns",
      returnOrders: [],
      pagination: {
        pages: [],
        currentPage: 1,
        pageSize: 20,
        totalPages: 1,
      },

      totalItems: 0,
      filter: {
        number: null,
      },

      dateFormat: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      },
    };
  },

  components: {
    StartRequest,
  },

  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: "Retouren",
        },
        {
          name: "keywords",
          content: "",
        },
        {
          name: "description",
          content: "",
        },
      ],
      title: "Retouren",
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  mounted() {
    this.loadReturnRequest();
  },

  methods: {
    loadReturnRequest() {
      this.$store
        .dispatch("rma/loadReturnRequests", {
          filterInput: this.filter,
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
        })
        .then((res) => {
          this.$set(this, "returnOrders", res.items);
          this.totalItems = res.total_count;
          this.totalPages = res.page_info.total_pages;
          this.initPagination();
        })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {});
    },

    initPagination() {},

    viewRequest(request) {
      this.$parent.showRequestDetails(request);
    },

    formatDateTime(datetime) {
      let formattedDate = new Date(datetime).toLocaleDateString(
        "nl-NL",
        this.dateFormat
      );
      return formattedDate;
    },

    initNewRequest() {},
  },
};
</script>
