<template>
  <div class="account-address-book">
    <h4 class="add-title" v-if="editAddressId > 0">{{ $t("edit_address") }}</h4>
    <h4 class="add-title" v-if="editAddressId == -1">
      {{ $t("new_address") }}
    </h4>
    <div class="address-block">
      <b-row>
        <b-col md="6">
          <h6 class="address-info-title">
            {{ $t("contact_information") }}
          </h6>
          <b-form-group
            class="account-inputs"
            id="first-name-group"
            :label="$t('form_first_name') + '*'"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="form.firstname"
              type="text"
              v-on:blur="validateFirstname()"
              :state="form.firstnameState"
              aria-describedby="firstname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-feedback">
              {{ form.firstnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="last-name-group"
            :label="$t('form_last_name') + '*'"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="form.lastname"
              type="text"
              v-on:blur="validateLastname()"
              :state="form.lastnameState"
              aria-describedby="lastname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="lastname-feedback">
              {{ form.lastnameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-1"
            v-model="isCompany"
            name="checkbox-1"
            class="pl-0 mb-3"
            >{{ $t("form_are_you_a_company") }}</b-form-checkbox
          >

          <!-- If Company -->
          <div v-if="isCompany">
            <b-form-group
              class="account-inputs"
              id="company-group"
              :label="$t('form_company_name') + '*'"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="form.company"
                type="text"
                v-on:blur="validateCompany()"
                :state="form.companyState"
                aria-describedby="company-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="company-feedback">
                {{ form.companyError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="coc-group"
              :label="$t('form_coc_number')"
              label-for="coc"
            >
              <b-form-input
                id="coc"
                v-model="form.coc"
                ariaOfInput="coc-feedback"
              />
              <b-form-invalid-feedback id="coc-feedback">
                {{ form.cocError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="taxvat-group"
              :label="$t('form_vat_number')"
              label-for="taxvat"
            >
              <b-form-input
                id="taxvat"
                v-model="form.taxvat"
                type="text"
                :state="form.taxvatState"
                aria-describedby="taxvat-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="taxvat-feedback">
                {{ form.taxvatError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-group
            class="account-inputs"
            id="telephone-group"
            :label="$t('form_phone') + '*'"
            label-for="telephone"
          >
            <b-form-input
              id="telephone"
              v-model="form.address.telephone"
              type="text"
              v-on:blur="validatePhone()"
              :state="form.address.telephoneState"
              aria-describedby="address.telephone-feedback"
              :placeholder="phonenumberPlaceholder"
            ></b-form-input>
            <b-form-invalid-feedback id="address.telephone-feedback">
              {{ form.address.telephoneError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h6 class="address-info-title">{{ $t("form_street") }}</h6>

          <!-- v-if="showFullAddress" -->
          <b-form-group
            class="account-inputs"
            id="postcode-group1"
            :label="$t('form_postal_code') + '*'"
            label-for="postcode1"
          >
            <b-form-input
              id="postcode1"
              v-model="form.address.postcode"
              type="text"
              v-on:blur="validatePostcode()"
              :state="form.address.postcodeState"
              aria-describedby="address.postcode1-feedback"
              :placeholder="postcodePlaceholder"
              :length="postcodeLength"
            ></b-form-input>
            <b-form-invalid-feedback id="address.postcode1-feedback">
              {{ form.address.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col sm="6" class="first">
              <b-form-group
                class="account-inputs"
                id="house_number-group"
                key="house_number-group"
                :label="$t('form_house_number') + '*'"
                label-for="house_number"
              >
                <b-form-input
                  id="house_number"
                  v-model="form.address.house_number"
                  type="text"
                  v-on:blur="validateHouseNumber()"
                  :state="form.address.house_numberState"
                  aria-describedby="address.house_number-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="address.house_number-feedback">
                  {{ form.address.house_numberError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" class="third">
              <b-form-group
                class="account-inputs"
                id="addition-group"
                key="addition-group"
                :label="$t('form_addition')"
                label-for="addition"
              >
                <b-form-input
                  id="addition"
                  v-model="form.address.addition"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            class="account-inputs"
            id="street-group"
            :label="$t('form_street') + '*'"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="form.address.streetDisplay"
              type="text"
              v-on:blur="validateStreet()"
              :state="form.address.streetDisplayState"
              aria-describedby="address.streetDisplay-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.streetDisplay-feedback">
              {{ form.address.streetDisplayError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- v-if="showFullAddress" -->
          <b-form-group
            class="account-inputs"
            id="city-group"
            :label="$t('form_city') + '*'"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="form.address.city"
              type="text"
              v-on:blur="validateCity()"
              :state="form.address.cityState"
              aria-describedby="address.city-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.city-feedback">
              {{ form.address.cityError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="country-group"
            :label="$t('form_country') + '*'"
            label-for="country"
          >
            <b-form-select
              id="country"
              v-model="form.address.country_code"
              :options="countries"
              class="select"
            ></b-form-select>
          </b-form-group>

          <!-- v-if="showFullAddress" -->

          <b-form-group
            v-if="foundAddress != '' && showFullAddress == false"
            class="account-inputs"
            id="add-group"
            label=" "
            label-for="add"
          >
            <div id="add">
              {{ foundAddress }}
            </div>
          </b-form-group>
          <b-form-group
            v-if="foundAddresses.length > 0 && showFullAddress == false"
            class="account-inputs"
            id="addm-group"
            label=" "
            label-for="addm"
          >
            <div id="addm">
              <b-form-radio
                style="width: 300px"
                class="account-radios"
                v-model="selectedAddress"
                v-for="(item, index) in foundAddresses"
                :key="index"
                name="some-radios"
                :value="index"
                >{{ item.text }}</b-form-radio
              >
            </div>
          </b-form-group>

          <b-form-checkbox
            id="default_billing"
            v-model="form.address.default_billing"
            name="default_billing"
            :disabled="billingUncheckableState"
            >{{ $t("form_default_billing") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="default_shipping"
            v-model="form.address.default_shipping"
            name="default_shipping"
            :disabled="shippingUncheckableState"
            >{{ $t("form_default_shipping") }}</b-form-checkbox
          >

          <!--           <b-form-checkbox
            v-if="showManual"
            id="manual"
            v-model="showFullAddress"
            name="manual"
            >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
          >

          <b-form-checkbox
            id="checkbox-2"
            name="checkbox-2"
            label-for="checkbox2"
            v-model="form.address.default_billing"
            >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
          >
          <b-form-checkbox
            id="checkbox-3"
            name="checkbox-3"
            label-for="checkbox3"
            v-model="form.is_subscribed"
            >{{ $t("form_newsletter_subscription") }}</b-form-checkbox
          > -->
        </b-col>
      </b-row>
    </div>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button @click="submit" variant="success">{{ $t("save") }}</b-button>
      <b-button @click="cancel" variant="cancel">{{ $t("back") }}</b-button>
    </div>
  </div>
</template>
<script>
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
} from "@storefront/core/data-resolver/user";

export default {
  name: "AccountEditAddress",
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
  },
  mounted() {
    history.pushState({}, "");
    var self = this;
    window.onpopstate = function (event) {
      event.preventDefault();
      self.editAddressId = 0;
      window.scrollTo({
        top: 0,
      });
    };

    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
    }
    if (this.editAddressId != null && this.editAddressId != -1) {
      const address = this.$store.getters["user/getAddressByID"](
        this.editAddressId
      );
      if (address) {
        if (address.company != null) {
          this.isCompany = true;
        } else {
          this.isCompany = false;
        }
        this.form.company = address.company;
        this.form.address.country_code = address.country_code;
        this.form.firstname = address.firstname;
        this.form.lastname = address.lastname;
        this.form.address.streetDisplay = address.street[0];
        this.form.address.house_number = address.street[1];

        if (address.company != null) {
          this.isCompany = true;
        } else {
          this.isCompany = false;
        }
        this.form.company = address.company;
        this.form.address.country_code = address.country_code;
        this.form.firstname = address.firstname;
        this.form.lastname = address.lastname;
        this.form.address.streetDisplay = address.street[0];
        this.form.address.postcode = address.postcode;
        this.form.address.city = address.city;
        this.form.address.telephone = address.telephone;
        if (address.default_billing) {
          this.billingUncheckableState = true;
        }
        this.form.address.default_billing = address.default_billing;
        if (address.default_shipping) {
          this.shippingUncheckableState = true;
        }
        this.form.address.default_shipping = address.default_shipping;
        if (address.vat_id != null) {
          this.form.taxvat = address.vat_id;
        } else {
          this.form.taxvat = "";
        }
        this.form.coc = address.coc || "";
      } else {
        if (typeof config.customers.defaultCountry != "undefined") {
          this.form.address.country_code = config.customers.defaultCountry;
        }
        if (config.postcode_validation.enabled == false) {
          this.manual = true;
          this.showManual = false;
        }
        if (config.vat_validation.enabled == false) {
          this.showVatCheck = false;
        }
      }
    }
  },
  data() {
    return {
      isCompany: false,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,

      form: {
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        coc: "",
        cocState: null,
        cocError: "",
        cocTimer: null,

        address: {
          id: null,
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: false,
          default_shipping: false,
        },
        checkbox2: false,
        manual: true,
        regExNlPhone:
          /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
        regExBePhone:
          /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
        regExDePhone:
          /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
        regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
      },
      billingUncheckableState: false,
      shippingUncheckableState: false,
      postcodePlaceholder: "1111AA",
      phonenumberPlaceholder: "0612345678",
      postcodeLength: 6,
    };
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.taxvatState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        Logger.debug("formSubmit", "fistname1", this.firstnameState)();
        if (this.form.firstnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "fistname", formValid)();
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.form.lastnameState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "lastname", formValid)();
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "postcode", formValid)();

      if (this.form.address.house_numberState != true) {
        this.validateHouseNumber();
        if (this.form.address.house_numberState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "house_number", formValid)();

      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      Logger.debug("formSubmit", "street", formValid)();
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "telephone", formValid)();
      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      Logger.debug("formSubmit", "city", formValid)();
      if (formValid == true) {
        const address = {};
        if (this.editAddressId > 0) {
          address.id = this.editAddressId;
        }
        address.company = this.form.company;
        address.country_code = this.form.address.country_code;
        address.firstname = this.form.firstname;
        address.lastname = this.form.lastname;

        const street = [this.form.address.streetDisplay];
        if (this.form.address.house_number != "") {
          street.push(this.form.address.house_number);
        }
        if (this.form.address.addition != "") {
          street.push(this.form.address.addition);
        }

        address.street = street;

        address.postcode = this.form.address.postcode;
        address.city = this.form.address.city;
        address.telephone = this.form.address.telephone;
        address.vat_id = this.form.taxvat;
        address.coc = this.form.coc;
        address.default_billing = this.form.address.default_billing;
        address.default_shipping = this.form.address.default_shipping;
        if (this.isCompany != true) {
          address.company = "";
          address.vat_id = "";
          address.coc = "";
        }
        if (this.editAddressId > 0) {
          const retval = await this.$store.dispatch("user/updateAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        } else if (this.editAddressId == -1) {
          const retval = await this.$store.dispatch("user/addAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        }
      }
    },
    cancel() {
      this.editAddressId = 0;
      window.scrollTo({
        top: 0,
      });
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            this.form.taxvat = this.form.taxvat.replace(/[.\s]/g, '');
            const retval = await validateVatNumber(this.form.taxvat);
            Logger.debug("vatcheck", "validateVat", retval.is_valid)();
            if (retval.is_valid == true) {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");
              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        Logger.debug(
          "this.form.company",
          "validateCompany",
          this.form.company
        )();
        if (this.form.company == "" || this.form.company == null) {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (
        this.form.address.telephone.substring(0, 3) == "+31" ||
        (this.form.address.country_code == "NL" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (this.form.address.telephone.match(this.regExNlPhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_nl_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+32" ||
        (this.form.address.country_code == "BE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.form.address.telephone.match(this.regExBePhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_be_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+49" ||
        (this.form.address.country_code == "DE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.form.address.telephone.match(this.regExDePhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_de_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.match(this.regExGenPhone) == null
      ) {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState = true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          this.foundAddresses = [];
          list.forEach(function (item) {
            if (this.form.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + item.letter;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
            } else {
              if (
                item.letter != null &&
                item.letter.toUpperCase() ==
                  this.form.address.addition.toUpperCase()
              ) {
                let address = item.street + " " + item.number;
                address = address + item.letter;
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.address.streetDisplay = item.street;
                this.form.address.city = item.city;
                this.form.address.cityState = true;
                this.form.address.street = item.street;
                this.foundAddress = address;
                this.validatePostcode();
                return;
              }
            }
          }, this);
          if (
            this.foundAddresses.length == 0 &&
            this.foundAddress.length == 0
          ) {
            this.form.address.streetDisplay = "";
            this.form.address.streetDisplayState = null;
            this.form.address.city = "";
            this.form.address.cityState = null;
            this.foundAddress = this.$t("postcode_not_found");
          }
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.address.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
    },
  },
  watch: {
    country() {
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
      switch (this.form.address.country_code) {
        case "NL":
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
          break;
        case "BE":
          this.postcodePlaceholder = "1111";
          this.phonenumberPlaceholder = "+3212345678";
          this.postcodeLength = 4;
          break;
        case "DE":
          this.postcodePlaceholder = "11111";
          this.phonenumberPlaceholder = "+491234567890";
          this.postcodeLength = 5;
          break;
        default:
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
  },
};
</script>
<style lang="scss" scoped>
.form-footer {
  @include phone-xl {
    .btn {
      font-size: 14px !important;
    }
  }
}
</style>
