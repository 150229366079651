<template>
  <div class="tab-four tab-content-detail">
    <div class="heading pb-head-5 mb-0">Facturen</div>
    <div class="sub-heading">
      {{ $t("account_invoice_heading") }}
    </div>
    <div class="invoices-download-link">
      <table class="download-link-table">
        <tr>
          <th>{{ $t("Download") }}</th>
          <th>{{ $t("invoice_date") }}</th>
          <th>{{ $t("amount") }}</th>
          <th>{{ $t("Status") }}</th>
        </tr>

        <tr v-for="invoice in myInvoices" :key="invoice.id">
          <td data-th="Download" class="download-link">
            <b-link
              class="text-decoration"
              :href="invoice.download_link"
              target="_blank"
              >{{ invoice.number }}</b-link
            >
          </td>
          <td data-th="Datum">
            <span class="date">{{
              new Date(invoice.invoice_date).toLocaleDateString(
                "nl-NL",
                dateFormat
              )
            }}</span>
          </td>
          <td data-th="Bedrag">
            {{ formatCurrency(invoice.total.base_grand_total.value) }}
          </td>
          <td data-th="Status">
            <h4 :class="getStatusColor(invoice.status)">
              {{ invoice.status_label }}
            </h4>
          </td>
        </tr>
      </table>
    </div>
    <div class="login-btn pt-30 text-center mt-2">
      <b-button variant="cancel" @click="goBack()">{{ $t("back") }}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountTabInvoices",
  data() {
    return {};
  },
  computed: {
    myOrders() {
      return this.$store.getters["user/getMyOrders"];
    },
    myInvoices() {
      return this.myOrders.reduce(
        (prev, curr) => [...prev, ...curr.invoices],
        []
      );
    },
    dateFormat() {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return options;
    },
  },
  mounted() {},
  methods: {
    convertDate(date) {
      let month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
    getStatusColor(status) {
      switch (status) {
        case 2: //Paid
          return "green";
        default:
          return "red";
      }
    },
    getExpiryDate(invoiceDate) {
      var date = new Date(invoiceDate);
      const month = date.getMonth();
      date.setMonth(date.getMonth() + 1);
      while (month === date.getMonth()) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    },
    goBack() {
      this.$router.push({
        path: "/account/",
        query: { name: "DataAndPreferences" },
      });
    },
  },
};
</script>
