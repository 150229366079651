<template>
  <div class="top-padding">
    <b-container>
      <Breadcrumbs />
      <b-row>
        <b-col lg="12">
          <!-- <h1 class="cms-pageTitle">{{ cmsPageContent.title }}</h1> -->
          <VRuntimeTemplate
            v-if="cmsPageContent.content"
            :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
          ></VRuntimeTemplate>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import config from "@config";
export default {
  components: {
    Breadcrumbs,
    VRuntimeTemplate,
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  computed: {
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
      link: [
        {
          rel: "canonical",
          href: this.cmsPageContent.canonical_url
            ? config.app_url + this.cmsPageContent.canonical_url
            : config.app_url +
              (/\/$/.test(this.$route.fullPath)
                ? this.$route.fullPath
                : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>

<style>
.cms-content {
  overflow-x: hidden;
}
.cms-content iframe {
  max-width: 100% !important;
}
</style>
